import PageFilters from "./PageFilters"; // Import Filters component for managing document filters
import styles from "./Menu.module.scss"; // Import styles for the Menu component
import Threads from "../Threads"; // Import Threads component for displaying chat history
import FileSelector, { ErrorBoundary } from "./FileSelector";
import { useEffect } from "react";
import useIsMobile from "../../../hooks/useIsMobile";


interface MenuProps {
  criterias: any;
  filters: any;
  setFilters: (filters: any) => void;
  page: string;
  selectedMenu: number;
  isUpdatingCriterias?: boolean;
  selectedMode: string;
}

// Menu component to manage filters and chat history
function Menu({ criterias, filters, setFilters, page, selectedMenu, isUpdatingCriterias = false, selectedMode}: MenuProps) {

  const MENU_TITLE = selectedMenu === 1 ? 
    selectedMode === "MYGEORGES" ? "Chat with my docs" : "Filters and select documents" : 
    selectedMenu === 2 ? 
    "My Chat History" : "";
  const isMobile = useIsMobile();

  console.log(selectedMenu);

  return (
    <div
      className={`${styles.menuContent} d-flex flex-column gap-2 h-100`}
      style={{ width: "100%", paddingLeft: "0px" }}
    >
      <h5 className="mb-0" style={{marginTop: 20, marginLeft: 10}}>
        {MENU_TITLE}
      </h5>

      <div className="d-flex flex-row flex-grow-1 width-full height-100 overflow-hidden custom-sidebar">
        <div className="flex-row flex-grow-1 width-full height-100" style={{ display: selectedMenu === 1 ? "flex" : "none" }}>
          {selectedMode !== "MYGEORGES" ? (
            <>
              <div className="d-flex flex-column flex-grow-1 overflow-y-scroll" style={{width: isMobile ? "100%" : "40%"}}>
                <PageFilters
                  criterias={criterias}
                  filters={filters}
                  setFilters={setFilters}
                  page={page}
                />
              </div>
              <div className="d-flex flex-column flex-grow-1 overflow-y-hidden" style={{width: isMobile ? "0vw" : "60%"}}>
                <ErrorBoundary>
                  <FileSelector 
                    setFilters={setFilters}
                    filters={filters}
                    page={page}
                    isUpdatingCriterias={isUpdatingCriterias}
                    selectedMode={selectedMode}
                  />
                </ErrorBoundary>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column flex-grow-1 overflow-y-hidden" style={{width: isMobile ? "100%" : "100%"}}>
              <ErrorBoundary>
                <FileSelector 
                  setFilters={setFilters}
                  filters={filters}
                  page={page}
                  isUpdatingCriterias={isUpdatingCriterias}
                  selectedMode={selectedMode}
                />
              </ErrorBoundary>
            </div>
          )}
        </div>
        <div style={{ display: selectedMenu === 2 ? "flex" : "none", width: "100%" }}>
          <Threads />
        </div>
      </div>
    </div>
  );
}

export default Menu; // Export Menu component for use in other parts of the application
