/**
 * External import
 */
import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from "react";
import { OverlayTrigger, Tooltip, ButtonGroup, Button } from "react-bootstrap";
import { FixedSizeList } from 'react-window';
import { debounce } from 'lodash';
/**
 * Internal import
 */

import useFetchWithMsal from "../../../hooks/useFetchWithMsal"; 
import { endpointsCf } from "../../../config/componentsConfig";
import styles from "./FileSelector.module.scss";

/**
 * ErrorBoundary
 */
interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, errorInfo) {
      console.error('Error caught by boundary:', error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return <div className="alert alert-danger">Une erreur est survenue lors du chargement des fichiers.</div>;
      }
      return this.props.children;
    }
  }
  
  /**
   * Composant FileSelector
   * Permet la sélection de fichiers dans une arborescence avec gestion de dossiers extensibles
   * 
   * @component
   * @param {Object} props
   * @param {Function} props.setFilters - Fonction pour mettre à jour les filtres de sélection
   * @param {Object} props.filters - État actuel des filtres
   * @param {string} props.page - Identifiant de la page courante
   * @param {boolean} [props.isUpdatingCriterias=false] - Indique si les critères sont en cours de mise à jour
   * @param {string} props.selectedMode - Mode de sélection actuel
   */
  interface FileSelectorProps {
    setFilters: (filters: any) => void;
    filters: any;
    page: string;
    isUpdatingCriterias?: boolean;
    selectedMode: string;
  }
  const FileSelector: React.FC<FileSelectorProps> = ({ setFilters, filters, page, isUpdatingCriterias = false, selectedMode }) => {
    /**
     * État contenant l'arborescence complète des fichiers et dossiers
     * @type {Array<FileTree>}
     * Structure: [{ id: string, name: string, type: 'file'|'folder', children?: FileTree[] }]
     */
    const [files, setFiles] = useState([]);

    /**
     * État contenant les IDs des fichiers sélectionnés
     * Utilisé pour gérer la sélection multiple de fichiers
     * @type {Array<string>}
     */
    const [selectedFiles, setSelectedFiles] = useState([]);

    /**
     * Set contenant les IDs des dossiers actuellement déployés
     * Permet de gérer l'état d'expansion/réduction des dossiers
     * @type {Set<string>}
     */
    const [expandedFolders, setExpandedFolders] = useState(new Set());

    /**
     * Indicateur de rafraîchissement en cours de l'arborescence
     * Évite les requêtes multiples simultanées
     * @type {boolean}
     */
    const [isRefreshing, setIsRefreshing] = useState(false);

    /**
     * Hook personnalisé pour les requêtes authentifiées avec MSAL
     * @type {Object}
     * @property {Function} execute - Fonction pour exécuter une requête
     * @property {boolean} isLoading - Indique si une requête est en cours
     */
    const { execute, isLoading} = useFetchWithMsal();

    /**
     * Cache mémorisé pour stocker les informations des fichiers par ID
     * Optimise la recherche des informations de fichiers
     * @type {Map<string, FileTree>}
     */
    const fileIdsCache = useMemo(() => new Map(), []);

    // Ajouter la référence pour suivre les changements de filtres
    const filtersRef = useRef(filters);

    // ---------------------Utils Mise à jour (arborescence et selection)---------------------
    
    const getAllFileIdsInFolder = (items) => {
      /**
     * Récupère tous les IDs des fichiers dans un dossier
     * @param items - Les éléments à parcourir
     * @returns Un tableau contenant tous les IDs des fichiers dans le dossier
     */
      let fileIds = [];
      items.forEach(item => {
        if (item.type === 'folder' && item.children) {
          fileIds = [...fileIds, ...getAllFileIdsInFolder(item.children)];
        } else if (item.type !== 'folder') {
          fileIds.push(item.id);
        }
      });
      return fileIds;
    };
  
    
    const findFileById = (items, id) => {
      /**
     * Recherche un fichier par son ID
     * @param items - Les éléments à parcourir
     * @param id - L'ID du fichier à trouver
     * @returns Le fichier trouvé ou null si non trouvé
     */
      for (const item of items) {
        if (item.id === id) return item;
        if (item.children) {
          const found = findFileById(item.children, id);
          if (found) return found;
        }
      }
      return null;
    };
  
    
    const handleFileSelect = useCallback((item) => {
      /**
     * Gestion de la sélection des fichiers
     * @param item - L'élément à sélectionner
     */
      if (item.type === 'folder') {
        const fileIds = getAllFileIdsInFolder(item.children);
        setSelectedFiles(prevSelected => {
          const prevSelectedSet = new Set(prevSelected);
          const allSelected = fileIds.every(id => prevSelectedSet.has(id));
          
          if (allSelected) {
            return prevSelected.filter(id => !fileIds.includes(id));
          } else {
            return [...new Set([...prevSelected, ...fileIds])];
          }
        });
      } else {
        setSelectedFiles(prevSelected => {
          if (prevSelected.includes(item.id)) {
            return prevSelected.filter(id => id !== item.id);
          }
          return [...prevSelected, item.id];
        });
      }
    }, []);

    
    const selectAllFiles = useCallback(() => {
     /**
     * Sélectionner tous les fichiers
     */
      const allFileIds = getAllFileIdsInFolder(files);
      const uniqueFileIds = [...new Set(allFileIds)];
      setSelectedFiles(uniqueFileIds);
    }, [files]);
    
    const getFolderSelectionState = useCallback((items) => {
      /**
     * Détermine l'état de sélection d'un dossier
     * 
     * @param {FileTree[]} items - Les éléments du dossier à vérifier
     * @returns {'none' | 'partial' | 'full'} État de sélection :
     *   - 'none' : aucun fichier sélectionné
     *   - 'partial' : certains fichiers sélectionnés
     *   - 'full' : tous les fichiers sélectionnés
     */
      if (!items || items.length === 0) return 'none';

      const fileIds = getAllFileIdsInFolder(items);
      if (fileIds.length === 0) return 'none';

      const selectedCount = fileIds.filter(id => selectedFiles.includes(id)).length;
      
      if (selectedCount === 0) return 'none';
      if (selectedCount === fileIds.length) return 'full';
      return 'partial';
    }, [selectedFiles, getAllFileIdsInFolder]);

    
    const flattenedItems = useMemo(() => {
      /**
     * Convertit l'arborescence hiérarchique en liste plate pour l'affichage
     * Prend en compte l'état d'expansion des dossiers pour n'inclure que
     * les éléments visibles
     * 
     * @type {Array<FlattenedItem>}
     * Structure: Array<{
     *   ...FileTree,
     *   level: number // Niveau de profondeur dans l'arborescence
     * }>
     */
      const flatList = [];
      
      /**
       * Fonction récursive pour aplatir l'arborescence
       * @param {FileTree[]} items - Éléments à aplatir
       * @param {number} level - Niveau de profondeur actuel
       */
      const flatten = (items, level = 0) => {
        items.forEach(item => {
          flatList.push({ ...item, level });
          if (item.type === 'folder' && expandedFolders.has(item.id) && item.children) {
            flatten(item.children, level + 1);
          }
        });
      };
      
      flatten(files);
      return flatList;
    }, [files, expandedFolders]);

    // ---------------------Fonctions de Mise à jours-------------------------------

    const refreshFiles = useCallback(async (SelectAll = true) => {
      /**
     * Rafraîchit l'arborescence des fichiers depuis le serveur
     * Met à jour l'état files et sélectionne tous les fichiers par défaut
     * 
     * @throws {Error} En cas d'échec de la requête
     * @returns {Promise<void>}
     */
      if (isRefreshing) {
        console.log("No refreshFiles (isRefreshing)");
        return;
      }
      try {
        setIsRefreshing(true);
        
        console.log("refreshFiles", filters);
        const data = await execute("POST", endpointsCf.docTree(page), {
          filters,
          selectedMode
        });
        
        if (data && Array.isArray(data)) {
          setFiles(data);
          const allFileIds = getAllFileIdsInFolder(data);
          if (SelectAll) {
            setSelectedFiles(allFileIds);
          }
        }
      } catch (err) {
        console.error('FetchFiles error:', err);
      } finally {
        setIsRefreshing(false);
      }
    }, [execute, filters, page, isRefreshing, selectedMode]);

    
    const updateFilters = useCallback(
      /**
     * Met à jour les chemins des fichiers sélectionnés dans les filtres
     * avec un délai pour éviter les mises à jour trop fréquentes
     */
      debounce((selectedFiles, files, setFilters) => {
        const selectedPaths = selectedFiles.map(id => {
          const file = fileIdsCache.get(id) || findFileById(files, id);
          if (file) {
            fileIdsCache.set(id, file);
            return file.path;
          }
          return null;
        }).filter(Boolean);

        setFilters(current => ({
          ...current,
          path: selectedPaths.length > 0 ? selectedPaths : []
        }));
      }, 1000),
      [fileIdsCache]
    );

    // ---------------------Mise à jours -------------------------------

    useEffect(() => {
    /**
    * Récupération de l'arborescence des fichiers au montage du composant
    */
      refreshFiles();
    }, [execute, page]);

    useEffect(() => {
      /**
       * Récupération de l'arborescence des fichiers lors d'un changement de PageFilters
       */
      console.log("isUpdatingCriterias", isUpdatingCriterias);
      if (!isRefreshing && isUpdatingCriterias) {
        refreshFiles();
      } else {
        console.log("No refreshFiles (PagesFilters not changed)");
      }
    }, [isUpdatingCriterias]);

    useEffect(() => {
      if (selectedMode === "MYGEORGES") {
        const hasPath = filters?.path && filters.path.length > 0;
        const previousHasPath = filtersRef.current?.path && filtersRef.current.path.length > 0;
        if (!hasPath) {
          refreshFiles(false);
        }
      }
    }, [filters]);

    
    useEffect(() => {
      /**
       * Mise à jour des filtres avec un délai pour éviter les mises à jour trop fréquentes
       */
      updateFilters(selectedFiles, files, setFilters);
      return () => {
        updateFilters.cancel();
      };
    }, [selectedFiles, files, setFilters, updateFilters]);

    // Ajouter un useEffect pour écouter l'événement newThread
    useEffect(() => {
      const handleNewThread = () => {
        console.log("New thread created, refreshing files...");
        refreshFiles();
      };

      window.addEventListener('newThread', handleNewThread);

      // Cleanup
      return () => {
        window.removeEventListener('newThread', handleNewThread);
      };
    }, [refreshFiles]);

    // ---------------------CONTROLS ---------------------

    const toggleFolder = (folderId) => {
      /**
       * Bascule l'état d'expansion d'un dossier
       */
      setExpandedFolders(prev => {
        const newSet = new Set(prev);
        if (newSet.has(folderId)) {
          newSet.delete(folderId);
        } else {
          newSet.add(folderId);
        }
        return newSet;
      });
    };
    
    const deselectAllFiles = useCallback(() => {
     /**
     * Désélectionne tous les fichiers
     */
      setSelectedFiles([]);
    }, []);
    
    const selectTheseFiles = useCallback((item) => {
    /**
     * Désélectionne tous les fichiers puis sélectionne l'élément spécifié
     * @param {FileTreeItem} item - L'élément (fichier ou dossier) à sélectionner exclusivement
     */
      deselectAllFiles();
      handleFileSelect(item);
    }, []);
    

    // ---------------------COMPOSANTS ---------------------

    const FlatFileTree = memo(({ index, style }) => {
      /**
       * Composant pour afficher un élément de l'arborescence des fichiers
       */
      const item = flattenedItems[index];
      const folderSelectionState = useMemo(() => {
        if (item.type === 'folder' && item.children) {
          return getFolderSelectionState(item.children);
        }
        return null;
      }, [item, getFolderSelectionState]);

      const itemContent = (
        <div style={{ ...style, marginLeft: `${item.level * 20}px` }}>
          {item.type === 'folder' ? (
            <div className={styles.fileSelectorComponent}>
              <div className="d-flex align-items-center" style={{ minWidth: '24px' }}>
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  checked={folderSelectionState === 'full'}
                  ref={input => {
                    if (input) {
                      input.indeterminate = folderSelectionState === 'partial';
                    }
                  }}
                  onChange={() => handleFileSelect(item)}
                  onDoubleClick={() => selectTheseFiles(item)}
                />
              </div>
              <div 
                className="d-flex align-items-center flex-grow-1"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolder(item.id);
                }}
              >
                <i className={`bi ${expandedFolders.has(item.id) ? 'bi-folder2-open' : 'bi-folder'} me-2`} />
                <span style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                  {item.name}
                </span>
              </div>
            </div>
          ) : (
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 0 }}
              overlay={
                <Tooltip 
                  id={`tooltip-${item.id}`}
                  className={styles.tooltip}
                  style={{ position: 'absolute' }}
                >
                  {item.name}
                </Tooltip>
              }
            >
              <div className={styles.fileSelectorComponent}>
                <div className="d-flex align-items-center" style={{ minWidth: '24px' }}>
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    checked={selectedFiles.includes(item.id)}
                    onChange={() => handleFileSelect(item)}
                    onDoubleClick={() => selectTheseFiles(item)}
                  />
                </div>
                <div 
                  className="d-flex align-items-center flex-grow-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleFileSelect(item)}
                  onDoubleClick={() => selectTheseFiles(item)}
                >
                  <i className={`bi me-2 ${getFileIcon(item.type)}`} />
                  <span style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {item.name}
                  </span>
                </div>
              </div>
            </OverlayTrigger>
          )}
        </div>
      );

      // Le tooltip n'est appliqué qu'aux fichiers, pas aux dossiers
      return itemContent;
    }, (prevProps, nextProps) => {
      const prevItem = flattenedItems[prevProps.index];
      const nextItem = flattenedItems[nextProps.index];
      return (
        prevItem.id === nextItem.id &&
        prevProps.style === nextProps.style
      );
    });

    const VirtualizedFlatFileTree = useMemo(() => (
      /**
       * Composant pour afficher l'arborescence des fichiers avec un rendu virtuel
       * Ajustement de la hauteur pour laisser de l'espace en bas
       */
      <FixedSizeList
        height={window.innerHeight - 250}
        width="100%"
        itemCount={flattenedItems.length}
        itemSize={35}
      >
        {FlatFileTree}
      </FixedSizeList>
    ), [
      flattenedItems.length,
      window.innerHeight,
      selectedFiles,
      expandedFolders
    ]);

    return (
      <div className="d-flex flex-column" style={{ height: "100%", width: "100%" }}>
        <div className="d-flex justify-content-between align-items-start mb-4 px-3 pt-3">
          <h6 className="mb-0">Available Documents</h6>
          <ButtonGroup size="sm">
            <Button 
              variant={isLoading ? "primary" : "primary-outlined"}
              onClick={refreshFiles}
              disabled={isLoading}
              title="Refresh"
            >
              <i className="bi bi-arrow-clockwise"></i>
            </Button>
            <Button 
              variant="secondary-outlined"
              onClick={selectAllFiles}
              disabled={isLoading || files.length === 0}
              title="Select All"
            >
              <i className="bi bi-check-all"></i>
            </Button>
            <Button 
              variant="secondary-outlined"
              onClick={deselectAllFiles}
              disabled={isLoading || selectedFiles.length === 0}
              title="Unselect All"
            >
              <i className="bi bi-square"></i>
            </Button>
          </ButtonGroup>
        </div>
        <div 
          className="flex-grow-1 px-3" 
          style={{ 
            height: 'calc(100% - 150px)',
            minWidth: '100%',
            overflow: 'hidden'
          }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : files.length === 0 ? (
            <p className="text-muted">No documents found</p>
          ) : VirtualizedFlatFileTree}
        </div>
        <div className="mt-3 px-3 pb-3 border-top pt-2">
          <small className="text-muted">
            {selectedFiles.length} document{selectedFiles.length !== 1 ? 's' : ''} selected
          </small>
        </div>
      </div>
    );
  };
  
  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'pdf':
        return 'bi-file-pdf text-danger';
      case 'doc':
      case 'docx':
        return 'bi-file-word text-primary';
      case 'xls':
      case 'xlsx':
        return 'bi-file-excel text-success';
      default:
        return 'bi-file-text';
    }
  };
  
  export { ErrorBoundary };
  export default FileSelector;
  