import React, { useState } from "react";
import { useChatContext } from "../../contexts/ChatContext";
import { Thread as ThreadType } from "../../types/types";
import styles from "./Threads.module.scss";

interface ThreadProps {
  thread: ThreadType;
}
const Thread: React.FC<ThreadProps> = ({ thread }) => {
  const { changeThread, selectedThread, abortQuery } = useChatContext();
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(thread.title);

  const onChangeThread = (thread: ThreadType) => {
    abortQuery();
    changeThread(thread);
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (editedTitle.trim()) {
      thread.title = editedTitle.trim();
      setIsEditing(false);
    }
  };

  return (
    <div
      className={`${styles.thread} ${
        selectedThread?.id === thread.id && styles.threadActive
      } d-flex justify-content-between align-items-center`}
      onClick={() => !isEditing && onChangeThread(thread)}
    >
      {isEditing ? (
        <form onSubmit={handleTitleSubmit} className="w-100" onClick={e => e.stopPropagation()}>
          <input
            type="text"
            value={editedTitle}
            onChange={handleTitleChange}
            onBlur={handleTitleSubmit}
            autoFocus
            className={styles.searchInput}
          />
        </form>
      ) : (
        <>
          <span>{thread.title}</span>
          <i 
            className="bi bi-pencil-square" 
            onClick={handleEditClick}
            style={{ fontSize: '0.8em', cursor: 'pointer' }}
          />
        </>
      )}
    </div>
  );
};

interface ThreadsProps {}

const Threads: React.FC<ThreadsProps> = () => {
  const { threads } = useChatContext();
  const [search, setSearch] = useState<string>("");

  const filteredThreads = threads.filter((thread) => {
    if (search) {
      return thread.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    } else {
      return true;
    }
  });

  return (
    <div className="d-flex flex-column gap-2 p-2 w-100 overflow-y-scroll" style={{maxHeight: "100%"}}>
      <input
        className={`${styles.searchInput}`}
        onChange={(event) => setSearch(event.target.value)}
        value={search}
        placeholder="Search..."
      />
      {threads.length === 0 ? (
        <div className="align-self-center small">
          <i className="bi bi-info-circle"></i> No chat history found yet...
        </div>
      ) : (
        filteredThreads.map((thread, idx) => (
          <Thread key={idx} thread={thread} />
        ))
      )}
    </div>
  );
};

export default Threads;
