import React from "react";
import { useChatContext } from "../../../contexts/ChatContext";
import ChatItemRenderer from "./ChatItemRenderer";
import Loader from "./Loader";

interface ChatItemsDisplayProps {}
const ChatItemsDisplay: React.FC<ChatItemsDisplayProps> = () => {
  const { chatItems, isLoadingQuery } = useChatContext();
  return (
    <>
      {chatItems.map((chatItem) => (
        <div key={chatItem.id} className="mb-2">
          <ChatItemRenderer chatItem={chatItem} />
        </div>
      ))}
      {isLoadingQuery && <Loader text="Generating response..." />}
    </>
  );
};
export default ChatItemsDisplay;
