import { InteractionType, PopupRequest } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import "bootstrap-icons/font/bootstrap-icons.css";
import React, { useEffect, useState } from "react";
import { loginRequest } from "../../config/authConfig";
import PageContent from "./PageContentNew";
import { ChatProvider } from "../../contexts/ChatContext";
import { endpointsCf } from "../../config/componentsConfig";
import { useMemo } from "react";

interface PageProps {
  page: 'home' | 'legal' | 'market' | 'mydeal';
}

const Page: React.FC<PageProps> = ({ page }) => {
  const [key, setKey] = useState(0);

  // Force le remontage du composant lors du changement de page
  useEffect(() => {
    setKey(prev => prev + 1);
  }, [page]);

  // Define the type for authRequest
  const authRequest: PopupRequest = {
    ...loginRequest,
  };

   // 2. Déplacer tous les useMemo et useCallback ici
  const endpoints = useMemo(
    () => ({
      threadsEndpoint: endpointsCf.threads(page),
      chatEndpoint: endpointsCf.chat(page),
      messagesEndpoint: endpointsCf.messages(page),
      feedbackEndpoint: endpointsCf.feedback(page),
      filtersEndpoint: endpointsCf.filters(page),
    }),
    [page]
  );

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <ChatProvider {...endpoints}>
        <PageContent key={key} page={page} />
      </ChatProvider>
    </MsalAuthenticationTemplate>
  );
};

export default Page;
