import {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import Alert from "react-bootstrap/Alert"; // Import for error messages
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Loader from "../../Loader";
import { useChatContext } from "../../../contexts/ChatContext";
import useAsset from "../../../hooks/useAsset";
import { isString } from "../../../types/typeGuards";
import { Source } from "../../../types/types";
import log from "../../../utils/logger";
import styles from "./SourceViewer.module.scss";

interface SourceViewerProps {
  page: 'home' | 'legal' | 'market' | 'mydeal';
  modeOptions: string;
}

function SourceViewer({ page, selectedMode }: SourceViewerProps) {
  const { fetchAsset, error: assetError } = useAsset();
  const { selectedSource, setSelectedSource } = useChatContext();
  const [sourceImage, setSourceImage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isWebPage, setIsWebPage] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");

  const getContainerName = useCallback(() => {
    // console.log("selectedMode", selectedMode);
    if (selectedMode === 'MYGEORGES') {
      return 'data-mygeorges';
    }
    switch (page) {
      case 'legal':
        return 'data-legal';
      case 'mydeal':
        return 'data-deal';
      case 'market':
        return 'data-market';
      default:
        return 'data-market';
    }
  }, [selectedMode, page]);

  const isValidUrl = (string: string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };
  const getPdf = useCallback(
    async (source: Source | null) => {
      log.debug("Getting source image", source);
      if (source) {
        try {
          const asset = {
            containerName: getContainerName(),
            fileName: `${source.fileName}.pdf`,
            fullPath: source.filePath ?? undefined,
            mediaType: "application/pdf",
          };
          const url = await fetchAsset(asset);
          if (url) {
            const pageNumber = isString(source.page)
              ? parseInt(source.page)
              : source.page;
            setPdfUrl(url + `#page=${pageNumber}`);
          } else {
            throw new Error("Failed to fetch PDF URL");
          }
        } catch (err) {
          log.error("Error fetching PDF:", err);
          setPdfUrl("");
        }
      }
    },
    [fetchAsset, getContainerName]
  );

  const getSourceImage = useCallback(
    async (source: Source | null) => {
      log.debug("Getting source image", source);

      if (!source) {
        setError("No source provided");
        return;
      }

      setError(null);
      setLoadingPdf(true);

      try {
        // Gestion des pages web
        if (source.filePath?.startsWith('http')) {
          setIsWebPage(true);
          return;
        }

        const containerName = getContainerName();
        const asset = {
          containerName: containerName,
          folder: "img",
          sourceName: `${source.fileName}_${source.page}`,
          extension: "jpg",
          mediaType: "image/jpeg",
        };

        const url = await fetchAsset(asset);
        if (!url) {
          throw new Error("Failed to load image");
        }
        setSourceImage(url);
      } catch (err) {

        if (err instanceof Error && !err.message.includes('400')) {
          log.error("Error fetching image:", err);
          setError("Failed to load image");
        }
      } finally {
        setLoadingPdf(false);
      }
      
    },
    [fetchAsset, getContainerName]
  );

  const resetSourceViewer = useCallback(() => {
    // Réinitialiser d'abord le selectedSource
    setSelectedSource(null);
    // Puis réinitialiser tous les autres états
    setSourceImage("");
    setPdfUrl("");
    setError(null);
    setShowModal(false);
    setLoadingPdf(false);
    setIsWebPage(false);
  }, [setSelectedSource]);

  // Effet pour la réinitialisation lors du changement de page
  useEffect(() => {
    resetSourceViewer();
  }, [page, resetSourceViewer]);

  useEffect(() => {
    if (!selectedSource) {
      return;
    }

    // Ouvrir directement les URLs web dans un nouvel onglet
    if (isValidUrl(selectedSource.filePath)) {
      window.open(selectedSource.filePath, '_blank', 'noopener,noreferrer');
      setSelectedSource(null); // Réinitialiser la source sélectionnée
      return;
    }

    setIsWebPage(false);
    setLoadingPdf(true);

    try {
      // Vérifier si le fichier est dans le conteneur data-legal
      if (selectedSource.filePath?.includes('data-legal') || page === 'legal') {
        getPdf(selectedSource);
      } else {
        getSourceImage(selectedSource);
      }
    } catch (error) {
      setError("Failed to load document");
      log.error("Error loading document:", error);
    } finally {
      setLoadingPdf(false);
    }
  }, [selectedSource, getSourceImage, getPdf, page, setSelectedSource]);

  const openPdfAtPage: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event: MouseEvent) => {
      if (selectedSource) {
        setLoadingPdf(true);

        let asset;

        if (page === 'legal') {
          asset = {
            containerName: getContainerName(),
            fileName: `${selectedSource.fileName}.pdf`,
            fullPath: selectedSource.filePath ?? undefined,
            mediaType: "application/pdf",
            userId: window.currentUser?.email
          };
        } else if (page === 'mydeal') {
          asset = {
            containerName: getContainerName(),
            folder: "pdf",
            sourceName: `${selectedSource.fileName}`,
            extension: "pdf",
            mediaType: "application/pdf",
            path: `${selectedSource?.filePath}`,
          };
        } else {
          asset = {
            containerName: getContainerName(),
            folder: "pdf",
            sourceName: `${selectedSource.fileName}`,
            extension: "pdf",
            mediaType: "application/pdf",
            path: `${selectedSource?.filePath}`,
          };
        }
        const url = await fetchAsset(asset);
        const pageNumber = isString(selectedSource.page)
          ? parseInt(selectedSource.page)
          : selectedSource.page;

        // Open the PDF in a new window with specified dimensions
        const pdfWindow = window.open(
          `${url}#page=${pageNumber + 1}`,
          "_blank",
          "width=800,height=600"
        ); // Adjust width and height as needed
        if (pdfWindow) {
          pdfWindow.focus(); // Focus on the new window
        }

        setLoadingPdf(false); // Reset loading state after opening
      }
    },
    [fetchAsset, selectedSource, page]
  );

  const renderMedia = () => {
    return isWebPage ? (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Button
          variant="primary"
          onClick={() => window.open(selectedSource?.filePath, '_blank', 'noopener,noreferrer')}
        >
          Ouvrir la page web dans un nouvel onglet →
        </Button>
      </div>
    ) : pdfUrl ? (
      <iframe
        src={pdfUrl}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          minHeight: '500px'
        }}
        title="PDF content"
      />
    ) : sourceImage ? (
      <img
        src={sourceImage}
        className="mx-auto my-auto"
        style={{ width: "100%", cursor: "pointer" }}
        alt="Source"
        onClick={() => setShowModal(true)}
      />
    ) : error ? (
      <div className={styles.alertContainer}>
        <Alert variant="danger">{error}</Alert>
      </div>
    ) : (
      <Loader text={"Getting document..."} width="2rem" height="2rem" />
    );
  }

  return (
    <>
      {selectedSource && (
        <>
          {/*{isWebPage ? (
            <iframe
              src={selectedSource.filePath}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                minHeight: '500px'
              }}
              title="Web content"
            />
          ) : pdfUrl ? (
            <iframe
              src={pdfUrl}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                minHeight: '500px'
              }}
              title="PDF content"
            />
          ) : sourceImage ? (
            <img
              src={sourceImage}
              className="mx-auto my-auto"
              style={{ width: "100%", cursor: "pointer", filter: "blur(2px)" }}
              alt="Source"
              onClick={() => setShowModal(true)}
            />
          ) : error ? (
            <div className={styles.alertContainer}>
              <Alert variant="danger">{error}</Alert>
            </div>
          ) : (
            <Loader text={"Getting document..."} width="2rem" height="2rem" />
          )}
          <Button
            variant="outline-primary"
            size="sm"
            style={{ textAlign: "left" }}
            onClick={openPdfAtPage}
            disabled={loadingPdf}
          >
            {loadingPdf ? (
              <Loader
                text={"Opening PDF..."}
                width="1rem"
                height="1rem"
                orientation="horizontal"
              />
            ) : error ? (
              <span className={styles.buttonText}>Failed to load document</span>
            ) : (
              <span className={styles.buttonText}>
                Open PDF at page {Number(selectedSource.page) + 1}
              </span>
            )}
          </Button>*/}
          <Modal
            show={selectedSource !== null}
            onHide={() => setSelectedSource(null)}
            size="xl"
            centered
          >
            <Modal.Header style={{ justifyContent: "center" }} closeButton>
              <b
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={openPdfAtPage} //TODO review this
              >
                {selectedSource.fileName}
              </b>
              <i>[p.{selectedSource.page}]</i>
            </Modal.Header>
            <Modal.Body>
              {/*<Image src={sourceImage} fluid style={{ filter: "blur(4px)" }} />*/}
              {renderMedia()}
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default SourceViewer;
