import Markdown from "react-markdown";
import { useEffect, useState, useRef } from "react";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { isString } from "../../../../types/typeGuards";
import { Message } from "../../../../types/types";
import styles from "./ReformulationMessage.module.scss";
import MarkdownTable from "./components/MarkdownTable";
import { useChatContext } from "../../../../contexts/ChatContext";
import { Button, Row, Col } from "react-bootstrap";

interface ThoughtMessageProps {
  message: Message;
}

const ReformulationMessage: React.FC<ThoughtMessageProps> = ({ message }) => {
  const { sendQuery, setIsReformulation, isReformulation } = useChatContext();
  const [editedContent, setEditedContent] = useState(message.content);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleAccept = () => {
    if (message && isString(editedContent)) {
      sendQuery({
        query: editedContent,
        filters: {},
        question_type: 'PERPLEXITY',
        is_reformulated: true
      });
      setIsReformulation(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedContent(event.target.value);
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleReject = () => {
    setIsReformulation(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isReformulation) {
        if (event.key === 'Enter') {
          handleAccept();
        } else if (event.key === 'Escape') {
          handleReject();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isReformulation, message]);

  if (!isReformulation) return null;
  else {
    return (
      <div className={styles.step}>
        <div className={styles.container}>
          <Row className="mb-3">
            <Col>
              <div className={styles.header}>
                <strong>Georges will send the following synthesis to Perplexity. Please verify no sensitive information is included. Feel free to modify anything.</strong>
              </div>
            </Col>
          </Row>
        
          <Row className="mb-3">
            <Col>
              <div className={styles.result}>
                <textarea
                  ref={textareaRef}
                  value={editedContent}
                  onChange={handleInputChange}
                  className={styles.textarea}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className={styles.buttonContainer}>
              <Button 
                className={`${styles.optionButton} ${styles.btnSuccess}`}
                variant="light"
                onClick={handleAccept}
              >
                Send
              </Button>
              <Button 
                className={`${styles.optionButton} ${styles.btnDanger}`}
                variant="light"
                onClick={handleReject}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
};

export default ReformulationMessage;
