import React, { useState, useEffect, useRef, memo, useCallback, useLayoutEffect, useMemo } from "react";
import {
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
  Overlay
} from "react-bootstrap"; // Import OverlayTrigger, Tooltip, and ProgressBar for UI elements
import { useChatContext } from "../../../contexts/ChatContext"; // Custom hook for chat context
import useIsMobile from "../../../hooks/useIsMobile";
import styles from "./ChatInput.module.scss";
import { chatInputConfig } from "../../../config/componentsConfig";
import { endpointsCf, pagesContent } from "./../../../config/componentsConfig";
import useFetchWithMsal from  "../../../hooks/useFetchWithMsal";
import content from "../../../data/textboxContent.json";
import { useMsal } from "@azure/msal-react";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

// Define interfaces for content structure
interface Square {
  title: string;
  text: string;
  icon: string;
  mode: string;
  filters: Record<string, unknown>;
}

interface Container {
  key: string;
  title: string;
  tooltip: string;
  modeValue: string;
  icon: string;
  label: string;
  squares: Square[];
}

interface ContentPage {
  containers: Container[];
}

interface Content {
  market: ContentPage;
  legal: ContentPage;
  mydeal: ContentPage;
  legaloreima: ContentPage;
}



// Define the props interface for the ChatInput component
interface ChatInputProps {
  page: string; 
  filters: object; 
  selectedText: string; 
  selectedMode: string; 
  setSelectedMode: (mode: string) => void; 
  setSelectedMenu: (menu: number) => void;
  modeOptions: Array<{
    value: string; 
    icon: string; 
    label: string; 
    tooltip: string; 
  }>; 
}

// Define the props interface for MemoizedTextArea
interface MemoizedTextAreaProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isLoadingQuery: boolean;
  isReformulation: boolean;
  inputRef: React.RefObject<HTMLTextAreaElement>;
}

// Define the props interface for MemoizedSendButton
interface MemoizedSendButtonProps {
  isLoadingQuery: boolean;
  isReformulation: boolean;
  onClick: () => void;
  onAbort: () => void;
}

// Define the props interface to fetch do processing status for mygeorges
interface ProcessingStatus {
  files: Array<{
    filename: string;
    totalPages: number;
    processed: number;
  }>;
}

// worker PDF.js utilisé pour calculer totalPages 
GlobalWorkerOptions.workerSrc = pdfjsWorker;

//------COMPONENTS------

const MemoizedTextArea = memo(({ 
  value, 
  onChange, 
  onKeyDown, 
  isLoadingQuery, 
  isReformulation,
  inputRef 
}: MemoizedTextAreaProps) => {
  /**
 * Composant mémorisé pour la zone de texte
 * Gère l'affichage et le redimensionnement automatique de la zone de saisie
 */
  // Utiliser useLayoutEffect pour synchroniser avec le DOM
  useLayoutEffect(() => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={inputRef}
      disabled={isLoadingQuery || isReformulation}
      id="inlineFormInputGroup"
      placeholder={isReformulation ? "Reformulating..." : "Ask anything to Georges"}
      value={value || ""}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={styles.textarea}
    />
  );
});


const MemoizedSendButton = memo(({ isLoadingQuery, isReformulation, onClick, onAbort }: MemoizedSendButtonProps) => {
  const handleClick = () => {
    if (isLoadingQuery || isReformulation) {
      onAbort();
    } else {
      onClick();
    }
  };

  return (
    <Button variant="light" className={styles.sendButton} onClick={handleClick}>
      <i className={`bi ${isLoadingQuery || isReformulation ? 'bi-stop' : 'bi-arrow-up'}`} style={{color: "white"}}></i>
    </Button>
  );
});


// Nouveau composant pour la barre de progression
interface UploadProgressBarProps {
  userId: string;
  onComplete?: (status: ProcessingStatus) => void;
  processingStatus: ProcessingStatus;
  onStatusUpdate: (status: ProcessingStatus) => void;
  isProcessing: boolean;
}

const UploadProgressBar = memo(({ userId, onComplete, processingStatus, onStatusUpdate, isProcessing }: UploadProgressBarProps) => {
  const { execute } = useFetchWithMsal();
  const completedRef = useRef(false);
  const completedFilesRef = useRef(new Set<string>());
  
  // Réinitialiser les refs quand les fichiers changent
  useEffect(() => {
    if (processingStatus?.files) {
      completedRef.current = false;
      completedFilesRef.current = new Set();
    }
  }, [processingStatus?.files.map(f => f.filename).join(',')]);

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout;
    
    const checkProcessingStatus = async () => {
      try {
        if (!processingStatus?.files?.length) return;

        const updatedFiles = await Promise.all(processingStatus.files.map(async (file) => {
          if (completedFilesRef.current.has(file.filename)) {
            return null;
          }

          try {
            const response = await execute('POST', endpointsCf.docprocessbar(userId), {
              filename: file.filename
            });

            if (response && typeof response.processed === 'number') {
              if (response.processed >= file.totalPages) {
                completedFilesRef.current.add(file.filename);
                onComplete?.({
                  files: [{
                    filename: file.filename,
                    totalPages: file.totalPages,
                    processed: response.processed
                  }]
                });
                return null;
              }

              return {
                ...file,
                processed: response.processed
              };
            }
            return file;
          } catch (error) {
            console.error(`❌ Erreur pour ${file.filename}:`, error);
            return file;
          }
        }));

        const remainingFiles = updatedFiles.filter((file): file is NonNullable<typeof file> => 
          file !== null && !completedFilesRef.current.has(file.filename)
        );

        if (remainingFiles.length > 0) {
          onStatusUpdate({ files: remainingFiles });
        } else {
          completedRef.current = true;
          if (pollingInterval) {
            clearInterval(pollingInterval);
          }
        }
      } catch (error) {
        console.error('❌ Erreur lors de la vérification du statut:', error);
      }
    };

    if (isProcessing && !completedRef.current && processingStatus?.files?.length > 0) {
      console.log('🔄 Démarrage du polling pour:', processingStatus.files.map(f => f.filename));
      // Supprimer l'appel immédiat et utiliser uniquement l'intervalle
      pollingInterval = setInterval(checkProcessingStatus, 5000);
    }

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [isProcessing, processingStatus?.files, userId, execute, onComplete, onStatusUpdate]);

  if (!isProcessing || !processingStatus?.files?.length) return null;

  // Afficher uniquement les fichiers non complétés
  const activeFiles = processingStatus.files.filter(
    file => !completedFilesRef.current.has(file.filename)
  );

  if (activeFiles.length === 0) return null;

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressHeader}>
        <span>Traitement des documents ({activeFiles.length})</span>
      </div>
      {activeFiles.map((file) => (
        <div key={file.filename} className={styles.fileProgress}>
          <div className={styles.fileInfo}>
            <span className={styles.fileName}>{file.filename}</span>
            <span className={styles.pageCount}>
              {file.processed}/{file.totalPages} pages
            </span>
          </div>
          <ProgressBar
            now={(file.processed / file.totalPages) * 100}
            label={`${Math.round((file.processed / file.totalPages) * 100)}%`}
            variant={file.processed >= file.totalPages ? "success" : "primary"}
          />
        </div>
      ))}
    </div>
  );
});

interface FileUploadInfo {
  files: Array<{
    filename: string;
    totalPages: number;
  }>;
}

interface MemoizedControlsProps {
  selectedMode: string;
  modeOptions: Array<{
    value: string;
    icon: string;
    label: string;
    tooltip: string;
  }>;
  setSelectedMode: (mode: string) => void;
  isMobile: boolean;
  optionIcons: any;
  page: string;
  onFileUpload: (fileInfo: FileUploadInfo) => void;
  isProcessing: boolean;
  processingStatus: ProcessingStatus | null;
  onProcessingComplete: () => void;
  onStatusUpdate: (status: ProcessingStatus) => void;
}

const MemoizedControls = memo(({ 
  selectedMode, 
  modeOptions, 
  setSelectedMode, 
  isMobile, 
  optionIcons, 
  page, 
  onFileUpload,
  isProcessing,
  processingStatus,
  onProcessingComplete,
  onStatusUpdate
}: MemoizedControlsProps) => {
  const { execute } = useFetchWithMsal();
  const { instance } = useMsal();
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [currentFiles, setCurrentFiles] = useState<Array<{filename: string; totalPages: number}>>([]);
  const activeAccount = instance.getActiveAccount();

  // Modifier la fonction getTotalPages dans MemoizedControls
  const getTotalPages = async (file: File): Promise<number> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      
      reader.onload = async (e) => {
        try {
          if (!e.target?.result) {
            console.error('❌ Erreur de lecture du fichier');
            resolve(0);
            return;
          }

          const arr = new Uint8Array(e.target.result as ArrayBuffer);
          
          try {
            const loadingTask = getDocument({ data: arr });
            const pdf = await loadingTask.promise;
            console.log(`📚 Nombre de pages détecté pour ${file.name}: ${pdf.numPages}`);
            resolve(pdf.numPages);
          } catch (error) {
            console.error('❌ Erreur lors de la lecture du PDF:', error);
            
            try {
              const blob = new Blob([arr], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              const loadingTask = getDocument(url);
              const pdf = await loadingTask.promise;
              console.log(`📚 Nombre de pages détecté (2ème tentative) pour ${file.name}: ${pdf.numPages}`);
              URL.revokeObjectURL(url);
              resolve(pdf.numPages);
            } catch (error2) {
              console.error('❌ Échec de la lecture du PDF');
              resolve(0);
            }
          }
        } catch (error) {
          console.error('❌ Erreur générale:', error);
          resolve(0);
        }
      };

      reader.onerror = (error) => {
        console.error('❌ Erreur de lecture:', error);
        resolve(0);
      };

      reader.readAsArrayBuffer(file);
    });
  };


  const handleFileUpload = async (files: FileList) => {
    if (!files.length || !activeAccount) return;

      const formData = new FormData();
    formData.append('userId', activeAccount.username);
      
    console.log('📁 Fichiers sélectionnés:', Array.from(files).map(f => f.name));

      const filesWithPages = await Promise.all(
        Array.from(files).map(async (file) => {
        const totalPages = await getTotalPages(file);
        
        if (totalPages === 0) {
          console.error(`❌ Erreur: Impossible de lire le nombre de pages pour ${file.name}`);
          return null;
        }
          return {
            file,
          totalPages
          };
        })
      );

    // Filtrer les fichiers qui n'ont pas pu être lus
    const validFiles = filesWithPages.filter((f): f is NonNullable<typeof f> => f !== null);
    
    if (validFiles.length === 0) {
      console.error('❌ Aucun fichier n\'a pu être lu correctement');
      return;
    }

    validFiles.forEach(({ file }) => {
        formData.append('files', file);
      });

      try {
      const uploadResponse = await execute('POST', endpointsCf.upload, formData);
      console.log('⬆️ Réponse upload:', uploadResponse);
        
      const processFiles = validFiles.map(({ file, totalPages }) => ({
            filename: file.name,
        totalPages,
        processed: 0
      }));
      
      setCurrentFiles(processFiles);
      setShowUploadProgress(true);
      onFileUpload({
        files: processFiles
      });
      } catch (error) {
      console.error('❌ Erreur lors de l\'upload:', error);
      }
  };

  return (
    <>
        <div className={styles.buttonGroup}>
          {modeOptions
            .filter(option => option.value !== 'UNDEFINED')
            .map(option => (
            <OverlayTrigger
              key={option.value}
              placement="top"
              delay={{ show: 400, hide: 200 }}
              overlay={
                <Tooltip id={`tooltip-${option.value}`}>
                  {option.tooltip}
                </Tooltip>
              }
            >
              <Button 
                variant="light" 
                onClick={() => {
                  setSelectedMode(selectedMode === option.value ? 'UNDEFINED' : option.value)
                }}
                active={selectedMode === option.value}
                className={styles.customButton}
              >
                <i className={optionIcons[option.value as keyof typeof optionIcons].icon} style={{color: "grey"}}></i>
                {!isMobile && optionIcons[option.value as keyof typeof optionIcons].label}
              </Button>
            </OverlayTrigger>
          ))}
        {selectedMode === 'MYGEORGES' && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 400, hide: 200 }}
            overlay={
              <Tooltip id="tooltip-upload">
                Upload documents
              </Tooltip>
            }
          >
            <Button 
              variant="light"
              className={styles.plusButton}
              onClick={() => {
                const input = document.createElement('input');
                input.type = 'file';
                input.multiple = true;
                input.onchange = (e) => handleFileUpload((e.target as HTMLInputElement).files!);
                input.click();
              }}
            >
              <i className="bi bi-plus" style={{color: "grey"}}></i>
            </Button>
          </OverlayTrigger>
        )}
      </div>
      {showUploadProgress && activeAccount && processingStatus && (
        <div className={styles.activeProgressWrapper}>
          <UploadProgressBar 
            key={processingStatus.files.map(f => f.filename).join(',')}
            userId={activeAccount.username}
            onComplete={onProcessingComplete}
            processingStatus={processingStatus}
            onStatusUpdate={onStatusUpdate}
            isProcessing={isProcessing}
          />
        </div>
      )}
    </>
  );
});


/**
 * Composant principal de saisie du chat
 * Gère la saisie utilisateur, les différents modes de conversation et l'envoi des requêtes
 */
const ChatInput = memo(({ selectedMode, filters, setFilters, selectedText, page, setSelectedMode, modeOptions, setSelectedMenu }: ChatInputProps) => {
  const { 
    chatItems,
    sendQuery, 
    abortQuery, 
    isLoadingQuery,
    isReformulation
  } = useChatContext();
  
  // Ajouter l'état local pour l'input
  const [localInput, setLocalInput] = useState("");
  const [showModesIndications, setShowModesIndications] = useState(true);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const isMobile = useIsMobile();
  const filtersRef = useRef(filters);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [processingStatus, setProcessingStatus] = useState<ProcessingStatus | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processedFiles, setProcessedFiles] = useState<ProcessingStatus[]>([]);
  const [showHistory, setShowHistory] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(true);

  const modesIndications = useMemo(() => content[page].containers.find((container) => container.modeValue === selectedMode), [page, selectedMode]);

  useEffect(() => {
    if(localInput.length > 0) setSelectedMenu(0);
  }, [localInput.length]);

  // Modifier handleQuery pour utiliser localInput
  const handleQuery = useCallback(() => {
    if(showModesIndications) setShowModesIndications(false);
    if (localInput?.trim()) {
      const payload = {
        query: localInput,
        filters: filtersRef.current,
        question_type: selectedMode,
        is_reformulated: false,
        page: page
      };
      
      console.error('Sending query with payload:', payload);
      sendQuery(payload);
      setLocalInput("");
    }
  }, [localInput, selectedMode, sendQuery, page]);

  // Modifier handleInputChange pour utiliser setLocalInput au lieu de setQuery
  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalInput(event.target.value);
  }, []);

  // Modifier handleKeyDown pour utiliser localInput
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (localInput && localInput.trim()) handleQuery();
    }
  }, [localInput, handleQuery]);

  // Mis à jours des filtres
  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    if(showModesIndications && chatItems.length > 0) setShowModesIndications(false);
  }, [chatItems.length]);

  //  Mis à jours local input
  useEffect(() => {
    if (selectedText) {
      setLocalInput(selectedText);
    }
  }, [selectedText]);

  const handleFileUpload = async (fileInfo: FileUploadInfo) => {
    if (!fileInfo.files.length || !activeAccount) return;
    
    setShowUploadProgress(true);
    setIsProcessing(true);
    
    // Forcer un nouveau objet de statut pour déclencher le polling
    setProcessingStatus(prevStatus => {
      const existingFiles = prevStatus?.files || [];
      const newFiles = fileInfo.files.filter(newFile => 
        !existingFiles.some(existingFile => existingFile.filename === newFile.filename)
      );
      
      return {
        files: [...existingFiles, ...newFiles].map(file => ({
          ...file,
          processed: file.processed || 0
        }))
      };
    });
  };

  const cleanFilters = useCallback((currentFilters: any) => {
    if (!currentFilters) return {};
    const { path, ...cleanedFilters } = currentFilters;
    return Object.keys(cleanedFilters).length > 0 ? cleanedFilters : {};
  }, []);

  const handleProcessingComplete = useCallback((completedStatus: ProcessingStatus) => {
    if (!completedStatus?.files) return;
    
    // Ajouter à l'historique
    setProcessedFiles(prev => {
      const existingFilenames = new Set(prev.flatMap(status => 
        status.files.map(file => file.filename)
      ));
      
      const newFiles = completedStatus.files.filter(file => 
        !existingFilenames.has(file.filename)
      );
      
      if (newFiles.length === 0) return prev;
      
      // Forcer une nouvelle référence d'objet pour les filtres
      const updatedFilters = cleanFilters(filters);
      setFilters(updatedFilters);
      
      return [{ files: newFiles }, ...prev];
    });

    // Nettoyer le processingStatus en retirant les fichiers complétés
    setProcessingStatus(prevStatus => {
      if (!prevStatus?.files) return null;
      
      const remainingFiles = prevStatus.files.filter(file => 
        !completedStatus.files.some(completedFile => 
          completedFile.filename === file.filename
        )
      );
      
      // Si plus aucun fichier en traitement, on met à null
      if (remainingFiles.length === 0) {
        setIsProcessing(false);
        return null;
      }
      
      return { files: remainingFiles };
    });
  }, [setFilters, filters, cleanFilters]);

  const handleStatusUpdate = useCallback((newStatus: ProcessingStatus) => {
    console.log('📊 Mise à jour du statut:', newStatus);
    setProcessingStatus(prevStatus => {
      const existingFiles = prevStatus?.files || [];
      // Mettre à jour uniquement les fichiers présents dans newStatus
      const updatedFiles = existingFiles.map(existingFile => {
        const updatedFile = newStatus.files.find(
          newFile => newFile.filename === existingFile.filename
        );
        return updatedFile || existingFile;
      });
      
      // Ajouter les nouveaux fichiers qui n'existaient pas avant
      const newFiles = newStatus.files.filter(newFile => 
        !existingFiles.some(existingFile => existingFile.filename === newFile.filename)
      );
      
      return {
        files: [...updatedFiles, ...newFiles]
      };
    });
  }, []);

  return (
    <>
    {showModesIndications && !isMobile && (
      <div className={styles.titleContainer}>
        <h1>How can I help you?</h1>
        <h4 style={{color: "grey"}}> 
          Georges, your <i className="bi bi-lock"></i> secure AI assistant
        </h4>
      </div>
    )}
    
    <div className={styles.chatContainer}>
      <div className={styles.chatInputContainer}>
        <Form className={styles.searchform}>
          <div className={styles.inputWrapper}>
            <MemoizedTextArea
              value={localInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              isLoadingQuery={isLoadingQuery}
              isReformulation={isReformulation}
              inputRef={inputRef}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <MemoizedSendButton 
              isLoadingQuery={isLoadingQuery}
              isReformulation={isReformulation}
              onClick={handleQuery}
              onAbort={abortQuery}
            />
          </div>
        </Form>
      </div>

      {/* Modes */}
      <div className={styles.modesContainer}>
        <MemoizedControls 
          selectedMode={selectedMode}
          modeOptions={modeOptions}
          setSelectedMode={setSelectedMode}
          isMobile={isMobile}
          optionIcons={chatInputConfig.optionIcons}
          page={page}
          onFileUpload={handleFileUpload}
        />
      </div>

      {/* Exemples et barres de progression dans un seul conteneur */}
      {(showModesIndications && !isMobile) || (selectedMode === 'MYGEORGES' && (isProcessing || processedFiles.length > 0)) ? (
        <div className={styles.modesIndicationsContainer}>
          {/* Exemples */}
          {showModesIndications && !isMobile && modesIndications?.squares?.map((square) => (
            <div 
              key={`${square.title}-${square.text}`} 
              className={styles.modesIndicationsItem} 
              onClick={() => setLocalInput(square.text)}
            >
              <i className={square.icon} style={{color: "grey", paddingLeft: 10, paddingRight: 10, paddingTop: 2}}></i>
              <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <p style={{ fontSize: "0.8rem", color: "grey", fontWeight: "bold"}}>{square.title}</p>
                <p style={{ fontSize: "0.8rem", color: "grey"}}>{square.text}</p>
              </div>
            </div>
          ))}

          {/* Section de progression et historique - uniquement pour MYGEORGES */}
          {selectedMode === 'MYGEORGES' && (isProcessing || processedFiles.length > 0) && (
            <div className={styles.progressAndHistoryContainer}>
              {/* Barre de progression - uniquement si des fichiers sont en cours de traitement */}
              {isProcessing && processingStatus && processingStatus.files.length > 0 && (
                <div className={styles.progressSection}>
                  <button 
                    className={styles.toggleButton}
                    onClick={() => setShowProgressBar(!showProgressBar)}
                  >
                    <i className={`bi ${showProgressBar ? 'bi-chevron-down' : 'bi-chevron-up'}`} />
                    Active Uploads ({processingStatus.files.length})
                  </button>
                  
                  {showProgressBar && (
                    <div className={styles.activeProgressWrapper}>
                      <UploadProgressBar 
                        key={processingStatus.files.map(f => f.filename).join(',')}
                        userId={activeAccount?.username || ''}
                        onComplete={handleProcessingComplete}
                        processingStatus={processingStatus}
                        onStatusUpdate={handleStatusUpdate}
                        isProcessing={isProcessing}
                      />
                    </div>
                  )}
                </div>
              )}

              {/* Historique */}
              {processedFiles.length > 0 && (
                <div className={styles.historySection}>
                  <button 
                    className={styles.toggleButton}
                    onClick={() => setShowHistory(!showHistory)}
                  >
                    <i className={`bi ${showHistory ? 'bi-chevron-down' : 'bi-chevron-up'}`} />
                    Processing History ({processedFiles.length})
                  </button>
                  
                  {showHistory && (
                    <div className={styles.historyList}>
                      {processedFiles.map((status, index) => (
                        <div key={index} className={styles.historyItem}>
                          {status.files?.map(file => (
                            <div key={file.filename} className={styles.completedFile}>
                              <span className={styles.fileName}>{file.filename}</span>
                              <span className={styles.pageCount}>
                                {file.processed}/{file.totalPages} pages
                              </span>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
    </div>
    </>
  );
});

export default ChatInput;


