import { useCallback, useEffect, useRef, useState } from "react";
import log from "../utils/logger";

interface UseAutoScrollReturn {
  containerRef: React.RefObject<HTMLDivElement>;
  bottomRef: React.RefObject<HTMLDivElement>;
  scrollToBottom: () => void;
  isUserAtBottom: boolean;
}

/**
 * Custom hook to handle automatic scrolling to the bottom of a container.
 *
 * @returns {UseAutoScrollReturn} - Object containing refs, functions, and state for auto-scrolling.
 */
function useAutoScroll(): UseAutoScrollReturn {
  // Ref for the container element
  const containerRef = useRef<HTMLDivElement>(null);

  // Ref for the bottom element used for scrolling into view
  const bottomRef = useRef<HTMLDivElement>(null);

  // State to track if the user is at the bottom of the container
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);

  // State to track if the auto-scrolling is in progress
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  /**
   * Scrolls the container to the bottom by scrolling the bottomRef element into view.
   * Sets `isAutoScrolling` to true to indicate that auto-scrolling is in progress.
   */
  const scrollToBottom = useCallback(() => {
    if (bottomRef.current) {
      // log.debug("Scrolling to bottom");
      setIsAutoScrolling(true);
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      log.debug("Bottom reference is not available");
    }
  }, []);

  /**
   * Handles scroll events for the container.
   * Updates the `isUserAtBottom` state based on the scroll position.
   * Disables auto-scrolling if the user manually scrolls.
   */
  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const isAtBottom =
        Math.abs(
          container.scrollHeight - container.scrollTop - container.clientHeight
        ) < 5;
      log.debug(
        `Scroll event detected. User is ${
          isAtBottom ? "at" : "not at"
        } the bottom.`
      );
      setIsUserAtBottom(isAtBottom);

      // Disable auto-scrolling if the user manually scrolls
      if (isAutoScrolling) {
        setIsAutoScrolling(false);
      }
    } else {
      log.debug("Container reference is not available");
    }
  }, [isAutoScrolling]);

  // Attach scroll event listener to the container element
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      // Clean up the scroll event listener on unmount
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  // Auto-scroll to bottom if the user is at the bottom and auto-scrolling is not in progress
  useEffect(() => {
    log.debug("Effect triggered: user is at bottom?", isUserAtBottom);
    if (isUserAtBottom && !isAutoScrolling) {
      scrollToBottom();
    }
  }, [isUserAtBottom, isAutoScrolling, scrollToBottom]);

  // Initial scroll to bottom when component mounts
  useEffect(() => {
    // log.debug("Initial render: Scrolling to bottom");
    scrollToBottom();
  }, [scrollToBottom]);

  // Observe changes in container size and adjust scrolling as needed
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const observer = new ResizeObserver(() => {
      // log.debug("Container resized: user is at bottom?", isUserAtBottom);
      if (isUserAtBottom && !isAutoScrolling) {
        scrollToBottom();
      }
    });

    observer.observe(container);

    // Clean up the ResizeObserver on unmount
    return () => {
      log.debug("Cleanup: Disconnecting ResizeObserver");
      observer.disconnect();
    };
  }, [scrollToBottom, isUserAtBottom, isAutoScrolling]);

  return {
    containerRef,
    bottomRef,
    scrollToBottom,
    isUserAtBottom,
  };
}

export default useAutoScroll;
