// Threads as defined by backend
export interface Thread {
  id: string;
  title: string;
  createdAt?: Date;
  updatedAt: Date;
  userId?: string;
  messages?: Message[];
}

// Messages as they are received from backend
export interface Message {
  id: string;
  createdAt: Date;
  type: string;
  content: object | string;
  feedback: boolean;
  threadId: string;
  userId: string;
  carousel?: {
    carouselId: string; // Unique identifier for the carousel
    panelId: string; // Unique identifier for the panel
    panelLabel?: string; // Optional label for the panel
  }; // Optional carousel information
}

export function parseMessage(
  json: Omit<Message, "createdAt"> & { createdAt: string }
): Message {
  return {
    ...json,
    createdAt: new Date(json.createdAt), // Convert createdAt to a Date object
  };
}

export function parseThread(
  json: Omit<Thread, "updatedAt"> & { updatedAt: string }
): Thread {
  return {
    ...json,
    updatedAt: new Date(json.updatedAt),
  };
}
// Represents a panel within a carousel
export interface Panel {
  id: string; // Unique identifier for the panel
  title: string; // Title of the panel
  messages: Message[]; // List of messages in the panel
}

// Represents a carousel which holds multiple panels
export interface Carousel {
  id: string; // Unique identifier for the carousel
  type: "carousel"; // Type of the item (must be 'carousel')
  createdAt: Date; // Creation timestamp of the carousel
  panels: Panel[]; // List of panels in the carousel
}

export interface Source {
  page?: string | number;
  fileName: string;
  filePath: string;
  type: 'file' | 'url';
}

export interface SourceMessage extends Message {
  content: Source[];
}

export type ChatItem = {
  type: "user" | "system" | "source" | "thought" | "carousel" | "reformulation" ;
}

export class MessageBuffer {
  message: Message;
  constructor(message: Message) {
    this.message = message;
  }

  append(message: Message) {
    this.updateRecursively(this.message, message);
  }

  private updateRecursively(d1: any, d2: any) {
    for (const key in d2) {
      if (key in d1) {
        if (typeof d1[key] === "object" && typeof d2[key] === "object") {
          this.updateRecursively(d1[key], d2[key]);
        } else if (d1[key] !== d2[key]) {
          if (typeof d1[key] === "string" && typeof d2[key] === "string") {
            d1[key] += d2[key];
          } else {
            d1[key] = d2[key];
          }
        }
      } else {
        d1[key] = d2[key];
      }
    }
  }
}
