import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useCallback, useRef, useState } from "react";
import { loginRequest } from "../config/authConfig";
import { useError } from "../contexts/ErrorContext";
import { keysToCamelCase, keysToSnakeCase } from "../utils/caseConversion";

/**
 * Custom hook for making authenticated HTTP requests using MSAL (Microsoft Authentication Library).
 * Manages authentication, request formatting, and response handling.
 */
const useFetchWithMsal = () => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const { addError } = useError();

  // MSAL authentication result and any associated error
  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Popup,
    {
      ...loginRequest,
      account: instance.getActiveAccount() || undefined,
    }
  );

  /**
   * Function to execute an authenticated HTTP request.
   * Handles request setup, execution, and response processing.
   * @param method - HTTP method (e.g., GET, POST)
   * @param endpoint - API endpoint URL
   * @param requestData - Optional data to include in the request body for POST requests
   * @returns The response data from the API
   */
  const execute = useCallback(
    async (method: string, endpoint: string, requestData: object | FormData = {}) => {
      if (msalError) {
        setError(msalError);
        addError(`Authentication error: ${msalError.message}`);
        return;
      }

      if (result) {
        try {
          abortControllerRef.current = new AbortController();
          const { signal } = abortControllerRef.current;

          const headers = new Headers();
          headers.append("Authorization", `Bearer ${result.accessToken}`);
          headers.append("Access-Control-Expose-Headers", "");

          // Vérifier si requestData est une instance de FormData
          const isFormData = requestData instanceof FormData;

          // Configurer les headers en fonction du type de données
          if (method === "POST" && !isFormData) {
            headers.append("Content-Type", "application/json");
            const activeAccount = instance.getActiveAccount();
            if (activeAccount) {
              (requestData as any)["userId"] = activeAccount.username;
            }
            requestData = keysToSnakeCase(requestData);
          }

          const options: RequestInit = {
            method,
            headers,
            body: isFormData ? requestData : (method === "POST" ? JSON.stringify(requestData) : null),
            signal,
          };

          setIsLoading(true);

          const response = await fetch(endpoint, options);
          let responseData = null;

          if (response) {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseType = response.headers.get("Content-Type");
            if (responseType === "application/json") {
              responseData = await response.json();
              responseData = keysToCamelCase(responseData);
            } else if (responseType && ["application/pdf", "image/jpeg"].includes(responseType)) {
              responseData = await response.blob();
            }
            setData(responseData);
          }

          setIsLoading(false);
          return responseData;
        } catch (e: any) {
          if (e.name === "AbortError") {
            console.log("Fetch aborted");
          } else {
            setError(e);
            console.error("Error", e);
          }
          setIsLoading(false);
          throw e;
        } finally {
          abortControllerRef.current = null;
        }
      }
    },
    [result, msalError, instance, addError]
  );

  /**
   * Function to abort the ongoing request.
   */
  const abort = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the request if it is active
    }
  }, []);

  // Return the current state and functions for use in components
  return {
    isLoading,
    error,
    data,
    execute,
    abort,
  };
};

export default useFetchWithMsal;
