import { useCallback, useState } from "react";
import { useError } from "../contexts/ErrorContext";
import { errorToString } from "../types/typeGuards";
import useFetchWithMsal from "./useFetchWithMsal";

// Define the structure of asset data
interface AssetDataType {
  containerName?: string; // Optional name of the container
  folder?: string; // Optional folder name
  sourceName?: string; // Optional name of the source
  extension?: string; // Optional file extension
  mediaType?: string; // Optional media type (e.g., image, video)
  path?: string; // Optional path to the asset
}

// Custom hook for managing asset fetching
const useAsset = () => {
  const { execute, isLoading } = useFetchWithMsal(); // Fetch function and loading state from custom hook
  const [objectUrl, setObjectUrl] = useState<string | null>(null); // State to hold the object URL of the fetched asset
  const [error, setError] = useState<string | null>(null); // State to hold any error messages
  const { addError } = useError(); // Function to log errors in the context

  // Function to fetch an asset based on provided asset data
  const fetchAsset = useCallback(
    async (assetData: AssetDataType) => {
      try {
        // Execute the fetch request to the API
        const blob = await execute(
          "POST",
          `${process.env.REACT_APP_API_URI}/asset`, // API endpoint
          assetData // Data to be sent in the request
        );
        // Check if the response is a Blob
        if (blob instanceof Blob) {
          const url = URL.createObjectURL(blob); // Create a URL for the Blob
          setObjectUrl(url); // Update state with the new object URL
          setError(null); // Clear any previous errors
          return url; // Return the created URL
        } else {
          throw new Error("Received response is not a Blob"); // Handle unexpected response type
        }
      } catch (err) {
        // Convert error to a string for logging
        const errorMessage = errorToString(err);
        const fullErrorMessage = `Failed to fetch asset: ${errorMessage}`; // Construct full error message
        setError(fullErrorMessage); // Update state with the error message
        setObjectUrl(null); // Clear the object URL on error
        
        // Ne pas propager les erreurs 400 à l'ErrorBanner
        if (!errorMessage.includes('400')) {
          addError(fullErrorMessage); // Log the error in the context
        }
        
        // Propager l'erreur pour la gestion en amont
        throw new Error(fullErrorMessage);
      }
    },
    [execute, addError] // Dependencies for useCallback
  );

  // Return the loading state, fetch function, object URL, and error message
  return {
    isLoading,
    fetchAsset,
    objectUrl,
    error,
  };
};

export default useAsset; // Export the custom hook for use in other components
