/////////////////////
// EndpointsConfig //
/////////////////////

const API_BASE_URL = process.env.REACT_APP_API_URI || '';

export interface EndpointsConfig {
  threads: (page: string) => string;
  chat: (page: string) => string;
  messages: (page: string) => string;
  feedback: (page: string) => string;
  filters: (page: string) => string;
  docTree: (page: string) => string;
  upload: string;
  docprocessbar: (userId: string) => string;
}

export const endpointsCf: EndpointsConfig = {
  threads: (page: string) => `${API_BASE_URL}/${page}/session_manager/thread_ids`,
  chat: (page: string) => `${API_BASE_URL}/${page}/chat`,
  messages: (page: string) => `${API_BASE_URL}/${page}/session_manager/thread_history`,
  feedback: (page: string) => `${API_BASE_URL}/${page}/session_manager/feedback_update`,
  filters: (page: string) => `${API_BASE_URL}/${page}/filters`,
  docTree: (page: string) => `${API_BASE_URL}/${page}/doc_tree`,
  upload: `${API_BASE_URL}/upload`,
  docprocessbar: (userId: string) => `${API_BASE_URL}/docprocessbar/${userId}`,
};

//////////////////
// PagesContent //
//////////////////

//TextboxContent

interface Container {
  key: string;
  title: string;
  tooltip: string;
  modeValue: string;
  icon: string;
  label: string;
}

interface PageContent {
  pageTitle: string;
  containers: Container[];
}

interface PagesContent {
  [key: string]: PageContent;
}

export const pagesContent: PagesContent = {
  market: {
    pageTitle: "Market Intelligence",
    containers: [
      {
        key: "SEARCH",
        title: "Internal Search",
        tooltip: "Georges will always search for documents on internal database. Note that in his answers, Georges will not have access to your previous chat history",
        modeValue: "SEARCH",
        icon: "bi bi-database",
        label: "Database Search"
      },
      {
        key: "CHATGPT",
        title: "Azure Chat",
        tooltip: "Engage in a chat with Georges, he will not search for information on internal database. Georges will have access to the latest chat history.",
        modeValue: "CHAT",
        icon: "bi bi-chat",
        label: "ChatGPT"
      },
      {
        key: "PERPLEXITY",
        title: "External Search",
        tooltip: "Engage in a chat with Georges, he will not search for information on InfraVia's internal database. Georges will have access to web search results.",
        modeValue: "PERPLEXITY",
        icon: "https://scienzai.com/wp-content/uploads/2023/12/Perplexity-Logo.png",
        label: "Perplexity"
      },
      // {
      //   key: "MYGEORGES",
      //   title: "MyGeorges",
      //   tooltip: "Have a fluid conversation and let Georges decide if you ask a question (Database Search) or if you want to chat (ChatGPT)",
      //   modeValue: "MYGEORGES",
      //   icon: "bi bi-folder",
      //   label: "My Georges"
      // },
      {
        key: "AI",
        title: "Georges chooses",
        tooltip: "Have a fluid conversation and let Georges decide if you ask a question (Database Search) or if you want to chat (ChatGPT)",
        modeValue: "UNDEFINED",
        icon: "bi bi-robot",
        label: "AI decides your mode"
      }
    ]
  },
  legal: {
    pageTitle: "Legal Assistant",
    containers: [
      {
        key: "SEARCH",
        title: "Database Search",
        tooltip: "Georges will search for legal documents on Legal internal database",
        modeValue: "SEARCH",
        icon: "bi bi-database",
        label: "Database Search"
      },
      {
        key: "CHATGPT",
        title: "Discussion",
        tooltip: "Engage in a chat with Georges, where it has a memory of previous interactions",
        modeValue: "CHAT",
        icon: "bi bi-chat",
        label: "Discussion"
      },
      {
        key: "PERPLEXITY",
        title: "External Search",
        tooltip: "Engage in a chat with Georges, he will not search for information on InfraVia's internal database. Georges will have access to web search results.",
        modeValue: "PERPLEXITY",
        icon: "https://scienzai.com/wp-content/uploads/2023/12/Perplexity-Logo.png",
        label: "Perplexity"
      },
      {
        key: "AI",
        title: "Georges chooses",
        tooltip: "Let Georges decide if you need a database search or a chat for your question",
        modeValue: "UNDEFINED",
        icon: "bi bi-robot",
        label: "AI decides your mode"
      }
    ]
  },
  mydeal: {
    pageTitle: "My Deal Analysis",
    containers: [
      {
        key: "SEARCH",
        title: "Database Search",
        tooltip: "Georges will always search for documents on internal database. Note that in his answers, Georges will not have access to your previous chat history",
        modeValue: "SEARCH",
        icon: "bi bi-database",
        label: "Database Search"
      },
      {
        key: "CHATGPT",
        title: "ChatGPT",
        tooltip: "Engage in a chat with Georges, he will not search for information on internal database. Georges will have access to the latest chat history.",
        modeValue: "CHAT",
        icon: "bi bi-chat",
        label: "ChatGPT"
      },
      {
        key: "PERPLEXITY",
        title: "External Search",
        tooltip: "Engage in a chat with Georges, he will not search for information on InfraVia's internal database. Georges will have access to web search results.",
        modeValue: "PERPLEXITY",
        icon: "https://scienzai.com/wp-content/uploads/2023/12/Perplexity-Logo.png",
        label: "Perplexity"
      },
      {
        key: "AI",
        title: "Georges chooses",
        tooltip: "Have a fluid conversation and let Georges decide if you ask a question (Database Search) or if you want to chat (ChatGPT)",
        modeValue: "UNDEFINED",
        icon: "bi bi-robot",
        label: "AI decides your mode"
      }
    ]
  }
};

//////////////////
// FiltersConfig //
//////////////////

interface FilterConfig {
  id: string;
  label: string;
  type: string;
  tooltip: string;
  default?: any;
}

export const LegalmainFilters: FilterConfig[] = [
  {
    "id": "dealName",
    "label": "Deal",
    "type": "multiselect",
    "tooltip": "Select one or more deals to filter. When multiple deals are chosen, a comparative table is automatically generated by Georges"
  },
  {
    "id": "docType",
    "label": "Document type",
    "type": "multiselect",
    "tooltip": "Filter by document type categories such as SHA (Shareholder Agreements), SFA and CTA (Senior Facilities Agreements / Common Terms Agreement), SA (Sales Agreement), ICA (Intercreditor Agreement), Hedging (ISDA, FBA)"
  },
  {
    "id": "docName",
    "label": "Document name",
    "type": "multiselect",
    "tooltip": "Filter by specific document names to narrow down your search within selected deals and document types"
  }
];

export const LegaldealFilters: FilterConfig[] = [
    {
      "id": "status",
      "label": "Status",
      "type": "multiselect",
      "default": [
        "Live"
      ],
      "tooltip": "Filter deals based on their current status"
    },
    {
      "id": "fund",
      "label": "Fund",
      "type": "multiselect",
      "tooltip": "Filter deals based on the fund associated to the deal"
    },
    {
      "id": "companySize",
      "label": "EBITDA",
      "type": "range",
      "tooltip": "Filter deals based on the size of the target company"
    },
    {
      "id": "sector",
      "label": "Sector",
      "type": "multiselect",
      "tooltip": "Select one or more sectors to filter deals based on the industry or business area of the company involved"
    },
    {
      "id": "location",
      "label": "Geography",
      "type": "multiselect",
      "tooltip": "Filter deals based on the country or region where the target is located"
    },
    {
      "id": "yearOfDeal",
      "label": "Year",
      "type": "range",
      "default": {
        "min": 2013,
        "max": 2024
      },
      "tooltip": "Select a range of years to filter deals based on when they were initiated or completed"
    },
    {
      "id": "shares",
      "label": "% of Shares held",
      "type": "range",
      "tooltip": "Filter deals based on the percentage of shares held"
    }
  ];

export const LegaldocFilters: FilterConfig[] = [
  {
    "id": "docStatus",
    "label": "Active/Inactive",
    "type": "multiselect",
    "default": [
      "Active"
    ],
    "tooltip": "Filter documents based on their current status"
  },
  {
    "id": "debt",
    "label": "Debt type",
    "type": "multiselect",
    "tooltip": "Select specific types of debt associated with the documents, if applicable"
  },
  {
    "id": "lender",
    "label": "Lender",
    "type": "multiselect",
    "tooltip": "Filter by lenders involved in the documentation, if any are specified"
  },
  {
    "id": "fundingSize",
    "label": "Amount",
    "type": "range",
    "tooltip": "Filter by the funding size or amount specified in the document"
  }
];


//////////////////
// ChatInputConfig //
//////////////////

export interface ChatInputConfig {
  optionIcons: {
    SEARCH: { icon: string; label: string };
    CHAT: { icon: string; label: string };
    PERPLEXITY: { icon: string; label: string };
    MYGEORGES: { icon: string; label: string };
    UNDEFINED: { icon: string; label: string };
  };
}

export const chatInputConfig: ChatInputConfig = {
  optionIcons: {
    "SEARCH": { icon: "bi bi-database", label: "Search Infravia" },
    "CHAT": { icon: "bi bi-chat", label: "Scribe & Translate" },
    "PERPLEXITY": { icon: "bi bi-globe", label: "Explore" },
    "MYGEORGES": { icon: "bi bi-folder", label: "Chat with my docs" },
    "UNDEFINED": { icon: "bi bi-binoculars", label: "Smart Search" },
  }
};

