import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useError } from "../contexts/ErrorContext";

const ErrorBanner: React.FC = () => {
  const { errors, removeError } = useError();

  return (
    <ToastContainer
      position="bottom-end"
      className="p-3"
      style={{ zIndex: 1100 }}
    >
      {errors.map((error, index) => (
        <Toast key={index} onClose={() => removeError(index)} bg="secondary" autohide={true} delay={5000}>
          <Toast.Header closeButton={false}>
            <strong className="me-auto">Oooops, I had a bug...</strong>
            <button
              type="button"
              className="btn-close"
              onClick={() => removeError(index)}
            ></button>
          </Toast.Header>
          <Toast.Body>
              <div>
                If the error remains, do not hesitate to send an email to Georges !
                <br />
              </div>
              <div style={{fontSize: 8}}>{error}</div>
          </Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};

export default ErrorBanner;
