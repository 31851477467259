import React, { useEffect, useState, useMemo } from "react"; // React and hooks for state and effects
import { Col, Row } from "react-bootstrap"; // Bootstrap components for layout
import { useChatContext } from "../../../contexts/ChatContext"; // Custom hook for chat context
import useAutoScroll from "../../../hooks/useAutoScroll"; // Custom hook for auto-scrolling functionality
import ChatInput from "./ChatInputNew"; // Import the ChatInput component for user input
import ChatItemsDisplay from "./ChatItemsDisplay"; // Component to display chat items
import { pagesContent } from "../../../config/componentsConfig";

// Define the props interface for the Chat component
interface ChatProps {
  filters: object; // Filters to apply to chat items
  setFilters: (filters: object) => void; // Function to set filters
  page: "market" | "legal" | "mydeal"; // Current page context
  selectedText: string; // Selected text
  selectedMode: string; // Selected mode
  setSelectedText: (text: string) => void; // Function to set selected text
  setSelectedMode: (mode: string) => void; // Function to set selected mode
  modeOptions: any[]; // Mode options
}

// Functional component for the chat interface
const Chat: React.FC<ChatProps> = ({
  filters,
  setFilters,
  page,
  selectedText,
  selectedMode,
  setSelectedMenu,
  setSelectedText,
  setSelectedMode,
  modeOptions, 
}) => {
  const { clearChat } = useChatContext(); // Get chat items from context and added clearChat
  const { containerRef, bottomRef} = useAutoScroll(); // Auto-scroll hooks

  // Effect for handling page change
  useEffect(() => {
    // Reset state when page changes
    clearChat();

    setSelectedText('');
    setSelectedMode('UNDEFINED');
    
    // Scroll to top of the conversation
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [page, clearChat, setSelectedText, setSelectedMode]);

  return (
    <Col className="d-flex flex-column h-100">
      <Row
        className="flex-grow-1 overflow-y-scroll custom-sidebar g-0 pt-4"
        ref={containerRef} // Reference for auto-scrolling
      >
        <div
          className="flex-fill d-flex flex-column justify-content-end px-3"
          style={{ minHeight: "100%" }} // Ensure minimum height for the chat area
        >

          <ChatItemsDisplay /> {/* Display chat items */}
          <div ref={bottomRef} /> {/* Reference for scrolling to the bottom */}
        </div>
      </Row>
      <Row className="g-0 px-3">
        <ChatInput
          page={page}
          filters={filters} // Pass filters to ChatInput
          setFilters={setFilters} // Pass filters to ChatInput
          selectedText={selectedText} // Pass selected text to ChatInput
          selectedMode={selectedMode} // Pass selected mode to ChatInput
          setSelectedMenu={setSelectedMenu} //Pass to close menu when user types
          setSelectedMode={setSelectedMode} // Handle mode change
          modeOptions={modeOptions} // Pass the renamed mode options to ChatInput
        />
      </Row>
    </Col>
  );
};

export default Chat; // Export the Chat component
