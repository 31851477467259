import React, { useMemo, useState, useEffect, useCallback } from "react";
import {Row, Col, Button,  } from "react-bootstrap";
import Chat from "./Chat/Chat";

import Menu from "./Menu/Menu";
import SourceViewer from "./SourceViewer/SourceViewerNew";
import { pagesContent, endpointsCf } from "../../config/componentsConfig";
import usePageFilters from "../../hooks/usePageFilters";
import useIsMobile from "../../hooks/useIsMobile";
import { useChatContext } from "../../contexts/ChatContext";
import styles from "./PageContent.module.scss";

interface PageContentProps {
  page: 'home' | 'legal' | 'market' | 'mydeal';
}

function PageContent({ page }: PageContentProps) {

  const { criterias, filters, setFilters, isUpdatingCriterias } = usePageFilters(endpointsCf.filters(page), page);
  const [selectedText, setSelectedText] = useState("");
  const [selectedMode, setSelectedMode] = useState("UNDEFINED");
  const isMobile = useIsMobile();
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(0); //0: Closed, 1: Filters and Files, 2: Chat History
  const { changeThread, abortQuery, changeSelectedSource } = useChatContext();

  const MENU_SIZE = isMobile ? "100vw" : "50vw";

  const pageContent = useMemo(() => pagesContent[page], [page]);

  const modeOptions = useMemo(() => {
    if (!pageContent?.containers) return [];
    return pageContent.containers.map((container) => ({
      value: container.modeValue,
      icon: container.icon,
      label: container.label,
      tooltip: container.tooltip,
    }));
  }, [pageContent]);


  useEffect(() => {

    if (["SEARCH", "UNDEFINED", "MYGEORGES"].includes(selectedMode)) {
      setSelectedMenu(1);
    }
    else {
      setSelectedMenu(0);
    }
  }, [selectedMode]);

  // Ajouter un useEffect pour gérer le changement de page
  useEffect(() => {
    // Réinitialiser le chat et le thread lors du changement de page
    handleNewThread();
  }, [page]); // Dépendance sur page

  // Function to handle creating a new thread and resetting the active tab
  const handleNewThread = useCallback(() => {
    changeThread(null);
    abortQuery();
    // setFilters({});
    changeSelectedSource(null);
    setSelectedMode("UNDEFINED");
    setSelectedText("");
  }, [changeThread, abortQuery, setFilters, changeSelectedSource, pageContent]);

  // 3. Vérification après tous les hooks
  if (!pageContent) {
    console.error(`No content found for page: ${page}`);
    return null;
  }


  return (
    <>
      <Row id="container" className="h-100 mh-100 overflow-hidden p-0">
        <div
          style={{
            width: Boolean(selectedMenu > 0) ? MENU_SIZE : "45px",
            display: "flex",
            flexDirection: "row",
            padding: 0,
            transition: "all 0.3s ease-in-out",
            height: "100%",
            backgroundColor: menuVisibility ? "#F6F6F6" : "transparent", 
            overflow: "hidden",
            zIndex: 1000,
            position: "relative"
          }}
          onTransitionEnd={() => setMenuVisibility(Boolean(selectedMenu > 0))}
        >
          <div style={{ 
                height: "fit-content",
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                marginTop: "10px",
                marginLeft: "5px",
                zIndex: 1001
              }}>
              <Button
              onClick={handleNewThread}
              title={"New Chat"}
              variant={"outline-secondary"}
              className={styles.menuButton}
              size="sm">
              <i className="bi bi-chat" style={{ fontSize: "20px" }}></i>
            </Button>
            <Button
              onClick={() => setSelectedMenu(selectedMenu === 2 ? 0 : 2)}
              title={"Chat History"}
              variant={selectedMenu === 2 ? "secondary" : "outline-secondary"}
              className={styles.menuButton}
              size="sm">
              <i className="bi bi-chat-left-text" style={{ fontSize: "20px" }}></i>
            </Button>
            {["SEARCH", "UNDEFINED", "MYGEORGES"].includes(selectedMode) &&
              <Button
                onClick={() => setSelectedMenu(selectedMenu === 1 ? 0 : 1)}
                title={"Filter and select documents"}
                variant={selectedMenu === 1 ? "secondary" : "outline-secondary"}
                className={styles.menuButton}
                style={{color: "#00afcd"}}
                size="sm">
                <i className="bi bi-menu-button-wide" ></i>
              </Button>}
          </div>
          <div style={{
            position: "absolute",
            top: 0,
            left: "45px",
            right: 0,
            bottom: 0,
            transform: menuVisibility ? "translateX(0)" : "translateX(-100%)",
            transition: "all 0.3s ease-in-out",
            height: "100%",
            width: "calc(100% - 45px)",
            backgroundColor: "#F6F6F6",
            opacity: menuVisibility ? 1 : 0
          }}>
            <Menu 
              page={page}
              selectedMenu={selectedMenu}
              criterias={criterias}
              filters={filters}
              setFilters={setFilters}
              isUpdatingCriterias={isUpdatingCriterias}
              selectedMode={selectedMode}
            />
          </div>
        </div>
        <Col className="h-100 overflow-auto">
        { (!(isMobile && menuVisibility)) &&
          <Chat
            page={page}
            filters={filters}
            setFilters={setFilters}
            selectedText={selectedText}
            selectedMode={selectedMode}
            setSelectedMenu={setSelectedMenu}
            setSelectedText={setSelectedText}
            setSelectedMode={setSelectedMode}
            modeOptions={modeOptions}
          />}
        </Col>
      </Row>
      {/* Modal Component */}
      <SourceViewer 
      page={page} 
      selectedMode={selectedMode}
      />
    </>
  );
}

export default PageContent;
