// src/components/Feedback.tsx

import React from "react";
import { useFeedback } from "../../../../../hooks/useFeedback";
import { Message } from "../../../../../types/types";
import styles from "./Feedback.module.scss";
import { useMsal } from "@azure/msal-react";
import { useChatContext } from "../../../../../contexts/ChatContext";

interface FeedbackProps {
  message: Message;
}

const Feedback: React.FC<FeedbackProps> = ({ message }) => {
  const { feedbackLoading, updateFeedback } = useFeedback();
  const { selectedThread } = useChatContext();

  const ThumbsUp = () => (
    <i
      className={`bi ${
        message.feedback === true
          ? "bi-hand-thumbs-up-fill"
          : "bi-hand-thumbs-up"
      } ${styles.thumbs} ${styles.thumbsUp}`}
      onClick={() => updateFeedback(message, true)}
    ></i>
  );

  const ThumbsDown = () => (
    <i
      className={`bi ${
        message.feedback === false
          ? "bi-hand-thumbs-down-fill"
          : "bi-hand-thumbs-down"
      } ${styles.thumbs} ${styles.thumbsDown}`}
      onClick={() => updateFeedback(message, false)}
    ></i>
  );

  const SendEmail = () => {

    const { accounts } = useMsal();
    const email = accounts[0]?.username;
    const threadName = selectedThread?.title;

    const subject = "Note from Georges: " + threadName;

    var cleanedContent = message.content.replace(/<[^>]*>?/g, '');
    cleanedContent = cleanedContent.replace(/[*_~#`]/g, '');
    //const body = marked(cleanedContent as string);

    return(
      <a 
        href={`mailto:${email}?subject=${subject}&body=${cleanedContent}`} 
        className={`${styles.thumbs}`}
        style={{marginLeft: 15}}
        onError={(e) => {
          console.error("Error sending email:", e);
        }}>
        <i className={`bi bi-envelope-fill`}></i>
      </a>)
  }

  return (
    <div className="d-flex flex-row justify-content-end gap-1 mx-2">
      {!feedbackLoading ? (
        <>
          <ThumbsDown />
          <ThumbsUp />
        </>
      ) : (
        <div
          className="spinner-grow spinner-grow-sm text-secondary"
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      )}
    </div>
  );
};

export default Feedback;
