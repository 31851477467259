import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import {
  EventSourceMessage,
  fetchEventSource,
} from "@microsoft/fetch-event-source";
import { useCallback, useEffect, useRef, useState } from "react";
import { loginRequest } from "../config/authConfig";
import { keysToSnakeCase } from "../utils/caseConversion";

interface UseSseWithMsalProps {
  handleMessage: (currentData: any[], event: EventSourceMessage) => any[];
}

/**
 * Custom hook for handling Server-Sent Events (SSE) with MSAL authentication.
 * Provides methods to execute requests and manage SSE connections.
 */
const useSseWithMsal = ({ handleMessage }: UseSseWithMsalProps) => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);
  const [data, setData] = useState<any[]>([]);

  // Ref for aborting ongoing requests
  const controller = useRef<AbortController | null>(null);

  const activeAccount = instance.getActiveAccount();
  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Popup,
    {
      ...loginRequest,
      account: activeAccount || undefined,
    }
  );

  /**
   * Constructs headers for the SSE request.
   * @param requestData - Optional request data
   * @returns Request headers
   */
  const constructHeaders = (requestData: object | null) => {
    const headers: { [key: string]: string } = {
      Authorization: `Bearer ${result?.accessToken || ""}`,
      "Access-Control-Expose-Headers": "",
      Accept: "text/event-stream",
    };
    if (requestData) headers["Content-Type"] = "application/json";
    return headers;
  };

  /**
   * Handles errors during SSE fetching.
   * @param err - Error object
   */
  const handleFetchError = (err: any) => {
    console.error("Error from server", err);
    setError(err);
    setIsLoading(false);
    throw err;
  };

  /**
   * Executes an SSE request with authentication and handles events.
   * @param method - HTTP method (e.g., GET)
   * @param endpoint - API endpoint URL
   * @param requestData - Optional request data
   */
  const execute = useCallback(
    async (
      method: string,
      endpoint: string,
      requestData: object | null = null
    ) => {
      if (msalError) {
        console.error("MSAL Error:", msalError);
        setError(msalError);
        return;
      }

      if (!result) {
        console.error("No authentication result");
        return;
      }

      if (result) {
        setIsLoading(true);
        try {
          setData([]); // Clear previous data
          controller.current = new AbortController();
          const { signal } = controller.current;

          const headers = constructHeaders(requestData);
          if (requestData) {
            requestData = keysToSnakeCase(requestData);
            (requestData as any)["user_id"] =
              instance.getActiveAccount()?.username || null;
          }

          await fetchEventSource(endpoint, {
            openWhenHidden: true,
            method,
            headers,
            body: requestData ? JSON.stringify(requestData) : null,
            signal,
            async onopen(res) {
              setData([]);
              if (res.ok && res.status === 200) {
                console.log("Connection established", res);
              } else if (
                res.status >= 400 &&
                res.status < 500 &&
                res.status !== 429
              ) {
                setIsLoading(false);
                console.log("Client-side error", res);
              }
            },
            onmessage: (event) => {
              try {
                setData((currentMessages) =>
                  handleMessage(currentMessages, event)
                );
              } catch (e) {
                console.error("Error processing message", e);
              }
            },
            onclose() {
              console.log("Connection closed by the server");
              setIsLoading(false);
            },
            onerror(err) {
              if (err.name === "AbortError") {
                console.log("Fetch aborted");
                setIsLoading(false);
              } else {
                handleFetchError(err);
              }
            },
          });
        } catch (e) {
          handleFetchError(e);
        }
      }
    },
    [result, msalError, activeAccount, data, handleMessage]
  );

  /**
   * Aborts the ongoing SSE request.
   */
  const abort = useCallback(() => {
    if (controller.current) {
      controller.current.abort();
      setIsLoading(false); // Set isLoading to false when aborting
    }
  }, []);

  useEffect(() => {
    // Cleanup abort controller on component unmount
    return () => {
      abort();
    };
  }, [abort]);

  return {
    isLoading,
    error,
    data,
    execute,
    abort,
  };
};

export default useSseWithMsal;
