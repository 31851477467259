import React, { createContext, useContext, useState, useEffect } from "react";
import { useChat } from "../hooks/useChat";
import { ChatItem, Source, Thread } from "../types/types";
import { useLocation } from "react-router-dom";

interface ChatContextProps {
  threads: Thread[];
  changeThread: (thread: Thread | null, fetch?: boolean) => void;
  selectedThread: Thread | null;
  sendQuery: (payload: { query?: string; [key: string]: any }) => void;
  abortQuery: () => void;
  isLoadingQuery: boolean;
  chatItems: ChatItem[];
  fetchMessages: (threadId: string) => void;
  feedbackEndpoint: string;
  selectedSource: Source | null;
  changeSelectedSource: (source: Source | null) => void;
  setSelectedSource: (source: Source | null) => void;
  setFilters: (filters: any) => void;
  clearChat: () => void;
  query: string;
  setQuery: (query: string) => void;
  isReformulation: boolean;
  setIsReformulation: (value: boolean) => void;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatProvider: React.FC<{
  children: React.ReactNode;
  threadsEndpoint: string;
  messagesEndpoint: string;
  chatEndpoint: string;
  feedbackEndpoint: string;
}> = ({
  children,
  threadsEndpoint,
  messagesEndpoint,
  chatEndpoint,
  feedbackEndpoint,
}) => {
  const [selectedSource, setSelectedSource] = useState<Source | null>(null);
  const [query, setQuery] = useState<string>("");
  const [isReformulation, setIsReformulation] = useState<boolean>(false);
  const location = useLocation();

  const chat = useChat(threadsEndpoint, messagesEndpoint, chatEndpoint, {
    setQuery,
    setIsReformulation
  });

  useEffect(() => {
    setSelectedSource(null);
    setIsReformulation(false);
    setQuery("");
  }, [location.pathname]);

  return (
    <ChatContext.Provider
      value={{
        ...chat,
        feedbackEndpoint,
        selectedSource,
        setSelectedSource,
        changeSelectedSource: setSelectedSource,
        clearChat: chat.clearChat,
        query,
        setQuery,
        isReformulation,
        setIsReformulation,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
