import { MultiSelect } from "primereact/multiselect";
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ConfigProvider, Slider } from "antd";
import styles from "./Filters.module.scss";
import addInfo from "../../../data/addInfo.json";
import { LegalmainFilters, LegaldealFilters, LegaldocFilters } from "../../../config/componentsConfig";

interface FilterProps {
  criterias: any;
  filters: any;
  setFilters: (filters: any) => void;
  page?: string;
}

const FilterLabel = ({ label, tooltip }: { label: string; tooltip: string }) => (
  <>
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={`tooltip-${label}`}>{tooltip}</Tooltip>}
      delay={{ show: 150, hide: 200 }}
    >
      <span className={styles.infoIconWrapper}>
        <span className={styles.infoIcon}>
          <i className="bi bi-info-circle"></i>
        </span>
      </span>
    </OverlayTrigger>
    <Form.Label style={{ fontSize: "0.75em" }}>{label}</Form.Label>
  </>
);

const cleanFilters = (filters: any) => {
  const { path, ...cleanedFilters } = filters;
  return cleanedFilters;
};

// Ajout du style pour le bouton Clear
const ClearButton = ({ onClick }: { onClick: () => void }) => (
  <button
    className="btn btn-outline-secondary btn-sm w-100 mb-3"
    onClick={onClick}
    style={{ fontSize: "0.75em" }}
  >
    Clear Filters
  </button>
);

// Composant pour la page MyDeal
const MyDealFilters: React.FC<FilterProps> = ({ criterias, filters, setFilters }) => {
  const createOptions = (items: string[] = []) =>
    items.map((item) => ({ label: item, value: item }));

  const handleClearFilters = () => {
    setFilters({});
  };

  return (
    <div className="d-flex flex-column width-full p-2">
      <ClearButton onClick={handleClearFilters} />
      <Form>
        <Form.Group className="mb-3">
          <FilterLabel
            label="Project name"
            tooltip="Choose the name/s of your projects"
          />
          <MultiSelect
            options={createOptions(criterias?.subIndustry?.sort())}
            value={filters?.subIndustry || []}
            onChange={(e) => setFilters(cleanFilters({ ...filters, subIndustry: e.value }))}
            filter
            placeholder=""
            scrollHeight="300px"
            display="comma"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
            pt={{
              root: { className: styles.small_multiselect },
              labelContainer: { className: styles.small_multiselect_label },
              token: { className: styles.small_multiselect_token },
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <FilterLabel
            label="Folder"
            tooltip="Choose the folder of the documents you want to search"
          />
          <MultiSelect
            options={createOptions(criterias?.projectName?.sort())}
            value={filters?.projectName || []}
            onChange={(e) => setFilters(cleanFilters({ ...filters, projectName: e.value }))}
            filter
            placeholder=""
            scrollHeight="300px"
            display="comma"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
            pt={{
              root: { className: styles.small_multiselect },
              labelContainer: { className: styles.small_multiselect_label },
              token: { className: styles.small_multiselect_token },
            }}
          />
        </Form.Group>
      </Form>
      <hr className="my-3" />
      <AddInfoSection />
    </div>
  );
};

// Composant pour la page Legal
const LegalFilters: React.FC<FilterProps> = ({ criterias, filters, setFilters }) => {
  const createOptions = (items: any[] = []) => {
    if (!items || !Array.isArray(items)) return [];
    return items.map((item) => ({
      label: item.toString(),
      value: item.toString()
    }));
  };

  const handleClearFilters = () => {
    setFilters({});
  };

  return (
    <div className="d-flex flex-column width-full p-2">
      <ClearButton onClick={handleClearFilters} />
      <Form>
        {LegalmainFilters.map((filter, idx) => (
          <Form.Group key={idx} className="mb-3">
            <FilterLabel
              label={filter.label}
              tooltip={filter.tooltip}
            />
            {filter.type === 'multiselect' ? (
              <MultiSelect
                options={createOptions(criterias?.[filter.id]?.sort())}
                value={filters?.[filter.id] || []}
                onChange={(e) => setFilters(cleanFilters({ ...filters, [filter.id]: e.value }))}
                filter
                placeholder=""
                scrollHeight="300px"
                display="comma"
                maxSelectedLabels={2}
                showClear
                style={{ width: "100%" }}
                pt={{
                  root: { className: styles.small_multiselect },
                  labelContainer: { className: styles.small_multiselect_label },
                  token: { className: styles.small_multiselect_token },
                }}
              />
            ) : filter.type === 'range' ? (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimaryBorderHover: "#00AFCD",
                  },
                  components: {
                    Slider: {
                      dotActiveBorderColor: "#00AFCD",
                      handleActiveColor: "#00AFCD",
                      handleColor: "#031A3B",
                      trackBg: "#031A3B",
                      trackHoverBg: "#00AFCD",
                      handleSizeHover: 10,
                      handleLineWidthHover: 3,
                    },
                  },
                }}
              >
                <Slider
                  range
                  value={[
                    filters?.[filter.id]?.min ?? criterias?.[filter.id]?.min ?? 0,
                    filters?.[filter.id]?.max ?? criterias?.[filter.id]?.max ?? 100
                  ]}
                  onChange={(v) =>
                    setFilters(cleanFilters({
                      ...filters,
                      [filter.id]: { min: v[0], max: v[1] },
                    }))
                  }
                  min={criterias?.[filter.id]?.min ?? 0}
                  max={criterias?.[filter.id]?.max ?? 100}
                  tooltip={{
                    formatter: (value) => {
                      if (filter.id === "shares") return `${Math.round(value)}%`;
                      if (filter.id === "fundingSize" || filter.id === "companySize") return `${value} M€`;
                      return value;
                    }
                  }}
                />
              </ConfigProvider>
            ) : null}
          </Form.Group>
        ))}

        <hr />
        <p style={{ color: "darkgrey", textAlign: "center" }}>
          <b>Additional deal filters</b>
        </p>
        {LegaldealFilters.map((filter, idx) => (
          <Form.Group key={idx} className="mb-3">
            <FilterLabel
              label={filter.label}
              tooltip={filter.tooltip}
            />
            {filter.type === 'multiselect' ? (
              <MultiSelect
                options={createOptions(criterias?.[filter.id]?.sort())}
                value={filters?.[filter.id] || []}
                onChange={(e) => setFilters(cleanFilters({ ...filters, [filter.id]: e.value }))}
                filter
                placeholder=""
                scrollHeight="300px"
                display="comma"
                maxSelectedLabels={2}
                showClear
                style={{ width: "100%" }}
                pt={{
                  root: { className: styles.small_multiselect },
                  labelContainer: { className: styles.small_multiselect_label },
                  token: { className: styles.small_multiselect_token },
                }}
              />
            ) : filter.type === 'range' ? (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimaryBorderHover: "#00AFCD",
                  },
                  components: {
                    Slider: {
                      dotActiveBorderColor: "#00AFCD",
                      handleActiveColor: "#00AFCD",
                      handleColor: "#031A3B",
                      trackBg: "#031A3B",
                      trackHoverBg: "#00AFCD",
                      handleSizeHover: 10,
                      handleLineWidthHover: 3,
                    },
                  },
                }}
              >
                <Slider
                  range
                  value={[
                    filters?.[filter.id]?.min ?? criterias?.[filter.id]?.min ?? 0,
                    filters?.[filter.id]?.max ?? criterias?.[filter.id]?.max ?? 100
                  ]}
                  onChange={(v) =>
                    setFilters(cleanFilters({
                      ...filters,
                      [filter.id]: { min: v[0], max: v[1] },
                    }))
                  }
                  min={criterias?.[filter.id]?.min ?? 0}
                  max={criterias?.[filter.id]?.max ?? 100}
                  tooltip={{
                    formatter: (value) => {
                      if (filter.id === "shares") return `${Math.round(value)}%`;
                      if (filter.id === "fundingSize" || filter.id === "companySize") return `${value} M€`;
                      return value;
                    }
                  }}
                />
              </ConfigProvider>
            ) : null}
          </Form.Group>
        ))}

        <hr />
        <p style={{ color: "darkgrey", textAlign: "center" }}>
          <b>Additional document filters</b>
        </p>
        {LegaldocFilters.map((filter, idx) => (
          <Form.Group key={idx} className="mb-3">
            <FilterLabel
              label={filter.label}
              tooltip={filter.tooltip}
            />
            <MultiSelect
              options={Array.isArray(criterias?.[filter.id]) ? createOptions(criterias?.[filter.id]?.sort()) : createOptions(criterias?.[filter.id])}
              value={filters?.[filter.id] || []}
              onChange={(e) => setFilters(cleanFilters({ ...filters, [filter.id]: e.value }))}
              filter
              placeholder=""
              scrollHeight="300px"
              display="comma"
              maxSelectedLabels={2}
              showClear
              style={{ width: "100%" }}
              pt={{
                root: { className: styles.small_multiselect },
                labelContainer: { className: styles.small_multiselect_label },
                token: { className: styles.small_multiselect_token },
              }}
            />
          </Form.Group>
        ))}

        <hr className="my-3" />
        <AddInfoSection />
      </Form>
    </div>
  );
};

// Composant pour les autres pages (comportement par défaut)
const DefaultFilters: React.FC<FilterProps> = ({ criterias, filters, setFilters }) => {
  const createOptions = (items: string[] = []) =>
    items.map((item) => ({ label: item, value: item }));

  const handleClearFilters = () => {
    setFilters({});
  };

  return (
    <div className="d-flex flex-column width-full p-2">
      <ClearButton onClick={handleClearFilters} />
      <Form>
        {/* Strategy/Sector Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Strategy"
            tooltip="Choose the strategy of your project"
          />
          <MultiSelect
            options={createOptions(criterias?.sector?.sort())}
            value={filters?.sector || []}
            onChange={(e) => setFilters(cleanFilters({ ...filters, sector: e.value }))}
            filter
            placeholder=""
            scrollHeight="300px"
            display="comma"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
            pt={{
              root: { className: styles.small_multiselect },
              labelContainer: { className: styles.small_multiselect_label },
              token: { className: styles.small_multiselect_token },
            }}
          />
        </Form.Group>

        {/* Macro Industry Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Macro Industry"
            tooltip="Choose the macro industry/industries of the projects"
          />
          <MultiSelect
            options={createOptions(criterias?.macroIndustry?.sort())}
            value={filters?.macroIndustry || []}
            onChange={(e) => setFilters(cleanFilters({ ...filters, macroIndustry: e.value }))}
            filter
            placeholder=""
            scrollHeight="300px"
            display="comma"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
            pt={{
              root: { className: styles.small_multiselect },
              labelContainer: { className: styles.small_multiselect_label },
              token: { className: styles.small_multiselect_token },
            }}
          />
        </Form.Group>

        {/* Sub Industry Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Sub Industry"
            tooltip="Choose the sub-industry/industries of the projects"
          />
          <MultiSelect
            options={createOptions(criterias?.subIndustry?.sort())}
            value={filters?.subIndustry || []}
            onChange={(e) => setFilters(cleanFilters({ ...filters, subIndustry: e.value }))}
            filter
            placeholder=""
            scrollHeight="300px"
            display="comma"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
            pt={{
              root: { className: styles.small_multiselect },
              labelContainer: { className: styles.small_multiselect_label },
              token: { className: styles.small_multiselect_token },
            }}
          />
        </Form.Group>

        {/* Project Year Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Project Year"
            tooltip="Choose the year/s of the project"
          />
          <MultiSelect
            options={createOptions(criterias?.year?.sort())}
            value={filters?.year || []}
            onChange={(e) => setFilters(cleanFilters({ ...filters, year: e.value }))}
            filter
            placeholder=""
            scrollHeight="300px"
            display="comma"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
            pt={{
              root: { className: styles.small_multiselect },
              labelContainer: { className: styles.small_multiselect_label },
              token: { className: styles.small_multiselect_token },
            }}
          />
        </Form.Group>

        {/* Document Type Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Document Type"
            tooltip="Choose the type/s of document that you want to search"
          />
          <MultiSelect
            options={createOptions(criterias?.type?.sort())}
            value={filters?.type || []}
            onChange={(e) => setFilters(cleanFilters({ ...filters, type: e.value }))}
            filter
            placeholder=""
            scrollHeight="300px"
            display="comma"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
            pt={{
              root: { className: styles.small_multiselect },
              labelContainer: { className: styles.small_multiselect_label },
              token: { className: styles.small_multiselect_token },
            }}
          />
        </Form.Group>

        {/* Project Name Filter */}
        <Form.Group className="mb-3">
          <FilterLabel
            label="Project Name"
            tooltip="Choose the name/s of your projects"
          />
          <MultiSelect
            options={createOptions(criterias?.projectName?.sort())}
            value={filters?.projectName || []}
            onChange={(e) => setFilters(cleanFilters({ ...filters, projectName: e.value }))}
            filter
            placeholder=""
            scrollHeight="300px"
            display="comma"
            maxSelectedLabels={2}
            showClear
            style={{ width: "100%" }}
            pt={{
              root: { className: styles.small_multiselect },
              labelContainer: { className: styles.small_multiselect_label },
              token: { className: styles.small_multiselect_token },
            }}
          />
        </Form.Group>
      </Form>
      <hr className="my-3" />
      <AddInfoSection />
    </div>
  );
};

// Composant AddInfo réutilisable
const AddInfoSection = () => (
  <div className="text-muted small">
    <p className="mb-0" style={{ fontSize: "1.05em", padding: "8px" }}>
      <strong>{addInfo.title}</strong>
    </p>
    {addInfo.items.map((item, index) => (
      <p key={index} className="mb-2" style={{ fontSize: "0.95em" }}>
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id={`tooltip-item-${index}`}>{item.tooltip}</Tooltip>}
        >
          <span className={styles.infoIcon} style={{ color: "#007bff" }}>
            <i className="bi bi-info-circle"></i>
          </span>
        </OverlayTrigger>{" "}
        {item.isBold
          ? item.text
              .split(new RegExp(`(${item.isBold.join("|")})`, "g"))
              .map((part, i) =>
                item.isBold.includes(part) ? <b key={i}>{part}</b> : part
              )
          : item.text}
      </p>
    ))}
  </div>
);

// Composant principal qui fait la dysjonction de cas
const PageFilters: React.FC<FilterProps> = ({ criterias, filters, setFilters, page }) => {
  switch (page) {
    case 'mydeal':
      return <MyDealFilters criterias={criterias} filters={filters} setFilters={setFilters} />;
    case 'legal':
      return <LegalFilters criterias={criterias} filters={filters} setFilters={setFilters} />;
    default:
      return <DefaultFilters criterias={criterias} filters={filters} setFilters={setFilters} />;
  }
};

export default PageFilters;
