import { useCallback, useEffect, useState, useRef } from "react";
import { useError } from "../contexts/ErrorContext";
import useFetchWithMsal from "./useFetchWithMsal";
import { errorToString } from "../types/typeGuards";
import { LegalmainFilters, LegaldealFilters, LegaldocFilters } from "../config/componentsConfig";

const usePageFilters = (endpoint: string, page?: string) => {
  const [filters, setFilters] = useState({});
  const [criterias, setCriterias] = useState({});
  const { execute: executeFetch } = useFetchWithMsal();
  const { addError } = useError();
  const previousFiltersStrRef = useRef("");
  const isInitialMount = useRef(true);
  const [isUpdatingCriterias, setIsUpdatingCriterias] = useState(false);

  const getDefaultFilters = useCallback(() => {
    if (page === 'legal') {
      const allConfigs = [...LegalmainFilters, ...LegaldealFilters, ...LegaldocFilters];
      const defaultFilters: Record<string, any> = {};

      allConfigs.forEach(filter => {
        if (filter.type === 'range' && filter.default) {
          defaultFilters[filter.id] = filter.default;
        } else if (filter.type === 'multiselect' && filter.default) {
          defaultFilters[filter.id] = filter.default;
        }
      });

      return defaultFilters;
    }
    return {};
  }, [page]);

  // Set default criterias on initial render and fetch initial data
  useEffect(() => {
    const defaultFilters = getDefaultFilters();
    setFilters(defaultFilters);
    setCriterias(defaultFilters);

    // Déclencher une requête initiale
    const initialFetch = async () => {
      try {
        console.log("Initial fetch of criterias from:", endpoint);
        const data = await executeFetch("POST", endpoint, {
          filters: defaultFilters,
        });
        console.log("Initial filters fetched:", data);
        setCriterias((prevFilters) => ({ ...prevFilters, ...data }));
      } catch (error) {
        const errorMessage = errorToString(error);
        console.error("Error fetching initial criterias:", errorMessage);
        addError(`Failed to fetch initial criterias: ${errorMessage}`);
      }
    };

    initialFetch();
  }, [getDefaultFilters, endpoint, executeFetch, addError]);

  const areFiltersChanged = useCallback((newFilters: any, oldFiltersStr: string) => {
    if (!newFilters) {
      return true;
    }
    
    const newFiltersStr = JSON.stringify(
      Object.fromEntries(
        Object.entries(newFilters).filter(([key]) => key !== 'path')
      )
    );
    
    return newFiltersStr !== oldFiltersStr;
  }, []);

  const fetchCriterias = useCallback(async () => {
    try {
      setIsUpdatingCriterias(true);
      console.log("Fetching criterias from:", endpoint);
      const { path, ...filtersWithoutPath } = filters;
      console.log("Filters for criterias:", filtersWithoutPath);
      
      const data = await executeFetch("POST", endpoint, {
        filters: filtersWithoutPath,
      });
      
      console.log("Filters fetched:", data);
      setCriterias((prevFilters) => ({ ...prevFilters, ...data }));
    } catch (error) {
      const errorMessage = errorToString(error);
      console.error("Error fetching criterias:", errorMessage);
      addError(`Failed to fetch criterias: ${errorMessage}`);
    } finally {
      setIsUpdatingCriterias(false);
    }
  }, [executeFetch, filters, addError, endpoint]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const { path: _, ...filtersWithoutPath } = filters;
    const currentFiltersStr = JSON.stringify(filtersWithoutPath);

    if (areFiltersChanged(filters, previousFiltersStrRef.current)) {
      console.log("fetchCriterias");
      const timer = setTimeout(() => {
        fetchCriterias();
      }, 500);
      
      previousFiltersStrRef.current = currentFiltersStr;
      return () => clearTimeout(timer);
    } else {
      console.log("No fetchCriterias (PagesFilters not changed)");
    }
  }, [filters, fetchCriterias, areFiltersChanged]);

  return { 
    criterias, 
    filters, 
    setFilters,
    fetchCriterias,
    isUpdatingCriterias
  };
};

export default usePageFilters;
